import React from "react"
import styled from "styled-components"
import { Colors, Responsive } from "../theme/styles"
import { Link } from "gatsby"
import {
  Container,
  Wrapper,
  Column,
  SEOTitle,
  Text,
  Row,
  Subtitle,
  Button,
} from "../theme"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const ServiceRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: ${props => (props.reverse ? "column-reverse" : "column")};
    align-items: center;
    width: 90%;
    margin: 50px auto 30px auto;
    justifycontent: center;
  `}
  ${Responsive.lg`
  flex-direction: row;
  align-items: flex-start;
  width: 95%;
  margin: 75px auto 75px auto;
  justifycontent: space-between;
`}
`

const BoxWrapper = styled.div`
  width: 80%;
  height: 100%;
  box-shadow: 0 20px 40px 0 rgba(0, 0, 0, 0.1);
  // padding: 80px;
  box-sizing: border-box;
  border: 1px solid ${Colors.lightBlue};
  position: relative;
  transition: 250ms;
  margin-top: 35px;
  @media (max-width: 980px) {
    width: 90%;
    text-align: center;
  }
`

const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  background: ${props => props.background};
  border: ${props =>
    props.border ? `1.5px solid ${Colors.lightBlue}` : "none"};
  transition: 250ms;
  border-radius: 8px;
  @media (min-width: 920px) {
    &:hover {
      box-shadow: 0 8px 24px 0 rgba(138, 145, 156, 0.2);
    }
  }
`

const DescriptionColumn = styled(Column)`
  ${Responsive.sm`
    align-items: center;
    width: 100%;
    margin: ${props => (props.margin ? "15px 0 0 0" : "0")};
    margin-right: ${props => (props.marginright ? "0px" : "0")};
    margin-left: ${props => (props.marginleft ? "0px" : "0")};
  `}
  ${Responsive.lg`
  align-items: center;
  width: 50%;
  margin: ${props => (props.margin ? "0 0 0 0px" : "0")};
  margin-right: ${props => (props.marginright ? "15px" : "0")};
  margin-left: ${props => (props.marginleft ? "15px" : "0")};
`}
`

const StyledButton = styled(Button)`
  background: ${Colors.darkBlue};
  &:hover {
    background: ${Colors.black};
    color: ${Colors.white};
  }
`

const StyledWrapper = styled(Wrapper)`
  background: ${props => props.background};
`
const StyledText = styled(Text)`
  ${Responsive.sm`
    width: 100%;
    color: ${props => props.color};
    text-align: left;
    margin: ${props => props.margin};
  `}
  ${Responsive.lg`
  width: ${props => (props.servicesPage ? "95%" : "85%")};
  text-align: left;
`}
`

const StyledTitle = styled(Subtitle)`
  color: ${props => props.color};
`

const StyledSEOTitle = styled(SEOTitle)`
  color: ${props => props.color || `${Colors.darkBlue}`};
`

const AnchorText = styled(Text)`
  text-decoration: underline;
  cursor: pointer;
  text-align: center;
`

export const ContentGrid = ({ type, background, color, seoTitle }) => {
  const images = useStaticQuery(graphql`
    query {
      headlesscms: file(relativePath: { eq: "blog/headlesscms.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      wordpress: file(relativePath: { eq: "blog/wixvswordpress.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      zippota: file(relativePath: { eq: "clientwork/zippotahome.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      parakeet: file(relativePath: { eq: "clientwork/parakeet.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      achievece: file(relativePath: { eq: "clientwork/achievece.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      interior: file(relativePath: { eq: "clientwork/interiormotives.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      nfd: file(relativePath: { eq: "clientwork/nfdwebsite.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      achieveceimage: file(
        relativePath: { eq: "clientwork/willachievece.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      featuredev: file(relativePath: { eq: "content/featuredev.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      mobileappdev: file(relativePath: { eq: "content/mobileappdev.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      devops: file(relativePath: { eq: "content/cloudstrategy.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      marketing: file(relativePath: { eq: "content/marketing.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      webdev: file(relativePath: { eq: "content/webdev.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      appdev: file(relativePath: { eq: "content/devcoding.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      socialmedia: file(relativePath: { eq: "content/socialmedia.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      emailmarketing: file(relativePath: { eq: "content/emailmarketing.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      funnel: file(relativePath: { eq: "content/funnel.jpg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      contentmarketing: file(
        relativePath: { eq: "content/contentmarketing.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      branding: file(relativePath: { eq: "content/branding.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const serviceData = [
    {
      img: images.webdev.childImageSharp.gatsbyImageData,
      title: "Website Design & Development",
      text: "Your website is the 24/7 sales and marketing tool that works for your business. We'll work with you to define your website's goals and purposes to ensure it's the conversion centric website it's meant to be in order to grow your revenue. We offer the latest and greatest technology so your website performs better and is more optimized than your competition.",
      flip: true,
      link: "website-design-development",
    },
    {
      img: images.marketing.childImageSharp.gatsbyImageData,
      title: "Digital Marketing",
      text: "Marketing is the bloodline of your business. We'll help you set up the best sales funnel that incorporates various forms of digital marketing including social media management and branding, email marketing, SEO, and setting up your sales funnel to ensure you're maximizing your goals. Our team is in constant communication to understand not just your business, but your competitors as well.",
      flip: false,
      link: "marketing-services",
    },
    {
      img: images.appdev.childImageSharp.gatsbyImageData,
      title: "Product Design & Development",
      text: "Technology continues to advance at a rapid rate and you don't want to lose a step behind the competition. Our team of experts can help you with your vision for your MVP, new feature development, or mobile application to help fuel the growth of your business and vault you ahead of your competitors.",
      flip: true,
      link: "web-mobile-application-development",
    },
  ]

  const techServices = [
    {
      img: images.achieveceimage.childImageSharp.gatsbyImageData,
      title: "Product Development",
      text: "Looking to execute your best ideas in the form of a new digital product? Focusing on scaling or optimizing what you already have? Here at CheshTech we're here to help you reach your goals! Our product development experts focus on building user-centric digital products that lead their respective markets helping you unlock your innovation, satisfy your users and grow your business.",
      link: "/work/case-study-achievece",
      linkText: "Learn More",
      border: true,
    },
    {
      img: images.featuredev.childImageSharp.gatsbyImageData,
      title: "Feature Development",
      text: "Have a product but looking at your next iteration to further enhance and scale your business? We focus on helping you with your product strategy and growth by assessing your product and helping you build out the next feature to satisfy your users. We ensure your feature is developed with your consumers in mind to give you the best potential to grow your product and company.",
      link: "/work/case-study-achievece",
      linkText: "Learn More",
      border: false,
    },
    {
      img: images.mobileappdev.childImageSharp.gatsbyImageData,
      title: "Mobile App Development",
      text: "It's no secret that modern consumers are using their phones more than ever to interact with their favorite brands and discover new companies. It's up to you to meet them where they live on mobile to grow your revenue and your market share. CheshTech can help you build and deploy new mobile applications faster, bring your UX to the next level, and grow your consumer base through mobile apps.",
      link: "/work/case-study-achievece",
      linkText: "Learn More",
      border: false,
    },
    {
      img: images.devops.childImageSharp.gatsbyImageData,
      title: "Dev Ops and Cloud Strategy",
      text: "Want to have a better understanding of your development processes and ensure that you're meeting your operational vision at a high level? CheshTech can help you optimize your resources using lean methodologies to reach your DevOps potential much faster. It's time to create a custom, scalable cloud roadmap! CheshTech's experts can help you provide precisely defined frameworks that are crafted from our experience with successful cloud strategy projects. We ensure that our strategy focuses on careful consideration of security requirements, potential management changes and needs, technical documentation, and improving processes. ",
      link: "/work/case-study-achievece",
      linkText: "Learn More",
      border: false,
    },
  ]

  const websiteServices = [
    {
      img: images.headlesscms.childImageSharp.gatsbyImageData,
      title: "Headless CMS: Latest and Greatest",
      text: "Technology is ever evolving and ensuring you're keeping up with the latest technology is an important part of staying ahead of your competitors. The introduction of the headless CMS has allowed businesses to take their website performance to the next level, while still pleasing their marketing team with the use of the WordPress CMS platform.",
      link: "/blog/what-is-a-headless-cms",
      btn: true,
      btnText: "What is a headless CMS? Learn more",
      border: true,
    },
    {
      img: images.zippota.childImageSharp.gatsbyImageData,
      title: "E-Commerce Websites",
      text: "Looking for a conversion centric e-commerce website that sets your business up for sales and provides a great user experience? The CheshTech team can provide you many options with the creation of your e-commerce store, including the use of integrating a headless CMS with Shopify so you can manage your products, but see conversions increase by up to 33%.",
      link: "/blog/what-is-a-headless-cms",
      btn: false,
      border: false,
    },
    {
      img: images.wordpress.childImageSharp.gatsbyImageData,
      title: "Traditional WordPress",
      text: "We build out and manage traditional WordPress websites as well and can help you best optimize your WordPress site's performance to give you the best opportunity to convert your website visitors. Our team of experts will ensure you have a WordPress website with the best performing and most optimized plugins as we create a website that best fits the goals and needs of your business.",
      link: "/blog/wix-vs-wordpress-vs-custom-websites",
      btn: true,
      btnText: "Understanding the different types of websites",
      border: true,
    },
    {
      img: images.nfd.childImageSharp.gatsbyImageData,
      title: "Get a Website in 10 days! $600",
      text: "Sometimes you just want something simple and quick! We can give you the quick turnaround time you need to build you out a quick single page website that will best fit your company's goals. Pick between a traditional WordPress website or utilize the newest technology with a headless CMS. You'll have this website in just 10 days so you can focus on what you do best.. Growing your business!",
      link: "/blog/what-is-a-headless-cms",
      btn: false,
      border: false,
    },
  ]

  const marketingServices = [
    {
      img: images.socialmedia.childImageSharp.gatsbyImageData,
      title: "Social Media and Community Management",
      text: "Hoping to increase your engagement and generate more leads through your social media marketing channels? The team at CheshTech can help you grow with targeted followers that will engage with your content and generate your target audience. We can help you across various social media channels including Facebook, Instagram, LinkedIn, TikTok, Pinterest, and Twitter. Social media is an essential part of your company's marketing success.",
    },
    {
      img: images.contentmarketing.childImageSharp.gatsbyImageData,
      title: "Content Creation",
      text: "You can't have a great social media and marketing presence without standout content that makes your audience stop while scrolling through their feed. Our social media experts also focus on content creation to ensure that your content catches the eyes of your target market. Branding on your social media channels plays an important role in your growth and converting followers into paying clients/customers. ",
    },
    {
      img: images.emailmarketing.childImageSharp.gatsbyImageData,
      title: "Email Marketing",
      text: "Capturing a list of emails from your target audience is one of the most efficient ways to generate long lasting sales for your company. We'll help you generate high converting email campaigns that will aim to educate and convert your audience into clients/customers. Email marketing is an essential part of e-commerce and services businesses to guarantee that you're making your best efforts to keep your target market engaged and coming back to buy more.",
    },
    {
      img: images.funnel.childImageSharp.gatsbyImageData,
      title: "Sales Funnel Creation and Consulting",
      text: "Feeling lost on how to funnel leads into paying customers/clients? Here at CheshTech we'll consult with you to fully understand your business and give you our expert opinion on the best way for your business to generate revenue based on your current sales funnel. Creating a funnel system that works for you to generate inbound leads is the key to long term business success.",
    },
  ]

  return (
    <StyledWrapper background={background}>
      <Container>
        <Column>
          <StyledSEOTitle
            color={
              color === `${Colors.black}`
                ? `${Colors.darkBlue}`
                : `${Colors.white}`
            }
            textAlign="center"
          >
            {seoTitle}
          </StyledSEOTitle>
          {type === "services" &&
            serviceData.map((key, index) => {
              const image = getImage(key.img)
              return (
                <ServiceRow key={index} reverse={key.flip === true}>
                  {key.flip === false && (
                    <DescriptionColumn marginright>
                      <GatsbyImage
                        image={image}
                        alt="CheshTech Services"
                        placeholder="blurred"
                      />
                    </DescriptionColumn>
                  )}
                  <DescriptionColumn margin>
                    <Subtitle white>{key.title}</Subtitle>
                    <StyledText white servicesPage margin="15px 0">
                      {key.text}
                    </StyledText>
                    <Link to={key.link}>
                      <StyledButton margin="6px 0 0 0">Learn More</StyledButton>
                    </Link>
                  </DescriptionColumn>
                  {key.flip === true && (
                    <DescriptionColumn marginleft>
                      <GatsbyImage
                        image={image}
                        alt="CheshTech Services"
                        placeholder="blurred"
                      />
                    </DescriptionColumn>
                  )}
                </ServiceRow>
              )
            })}
          {type === "techServices" &&
            techServices.map((key, index) => {
              const image = getImage(key.img)
              return (
                <BoxWrapper key={index}>
                  <ServiceRow reverse>
                    <DescriptionColumn margin>
                      <StyledTitle>{key.title}</StyledTitle>
                      <StyledText color={color} width="85%">
                        {key.text}
                      </StyledText>
                      <Link to="/schedule-consultation">
                        <StyledButton margin="15px 0 0 0">
                          Book a consultation
                        </StyledButton>
                      </Link>
                    </DescriptionColumn>
                    <DescriptionColumn marginleft>
                      {key.border === true && (
                        <BlogWrapper border>
                          <GatsbyImage
                            image={image}
                            alt="CheshTech Raquel Cespedes Diaz"
                            placeholder="blurred"
                          />
                          <Link to={key.link}>
                            <AnchorText margin="10px auto 10px auto">
                              {key.linkText}
                            </AnchorText>
                          </Link>
                        </BlogWrapper>
                      )}
                      {key.border === false && (
                        <GatsbyImage
                          image={image}
                          alt="CheshTech Raquel Cespedes Diaz"
                          placeholder="blurred"
                        />
                      )}
                    </DescriptionColumn>
                  </ServiceRow>
                </BoxWrapper>
              )
            })}
          {type === "websites" &&
            websiteServices.map((key, index) => {
              const image = getImage(key.img)
              return (
                <BoxWrapper key={index}>
                  <ServiceRow reverse>
                    <DescriptionColumn margin>
                      <StyledTitle>{key.title}</StyledTitle>
                      <StyledText color={color} width="85%">
                        {key.text}
                      </StyledText>
                      <Link to="/schedule-consultation">
                        <StyledButton margin="15px 0 0 0">
                          Book a consultation
                        </StyledButton>
                      </Link>
                    </DescriptionColumn>
                    <DescriptionColumn marginleft>
                      {key.btn === true && key.border === true && (
                        <BlogWrapper border>
                          <GatsbyImage
                            image={image}
                            alt="CheshTech Raquel Cespedes Diaz"
                            placeholder="blurred"
                            //   layout="fixed"
                            // width={200}
                            // height={200}
                          />
                          <Link to={key.link}>
                            <AnchorText
                              width="95%"
                              margin="10px auto 10px auto"
                            >
                              {key.btnText}
                            </AnchorText>
                          </Link>
                        </BlogWrapper>
                      )}
                      {key.border === false && (
                        <BlogWrapper>
                          <GatsbyImage
                            image={image}
                            alt="CheshTech Raquel Cespedes Diaz"
                            placeholder="blurred"
                            //   layout="fixed"
                            // width={200}
                            // height={200}
                          />
                          <Link to={key.link}>
                            <AnchorText
                              width="95%"
                              margin="10px auto 10px auto"
                            >
                              {key.btnText}
                            </AnchorText>
                          </Link>
                        </BlogWrapper>
                      )}
                    </DescriptionColumn>
                  </ServiceRow>
                </BoxWrapper>
              )
            })}
          {type === "marketing" &&
            marketingServices.map((key, index) => {
              const image = getImage(key.img)
              return (
                <BoxWrapper key={index}>
                  <ServiceRow reverse>
                    <DescriptionColumn margin>
                      <StyledTitle textAlign="center">{key.title}</StyledTitle>
                      <StyledText color={color} width="85%">
                        {key.text}
                      </StyledText>
                      <Link to="/schedule-consultation">
                        <StyledButton margin="15px 0 0 0">
                          Book a consultation
                        </StyledButton>
                      </Link>
                    </DescriptionColumn>
                    <DescriptionColumn marginleft>
                      <GatsbyImage
                        image={image}
                        alt="CheshTech Social Media"
                        placeholder="blurred"
                      />
                    </DescriptionColumn>
                  </ServiceRow>
                </BoxWrapper>
              )
            })}
        </Column>
      </Container>
    </StyledWrapper>
  )
}
