import React from "react"
import styled from "styled-components"
import {
  SEOTitle,
  Wrapper,
  Container,
  Column,
  Text,
  Row,
  Subtitle,
} from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const PodcastBox = styled.div`
  ${Responsive.sm`
  width: 100%;
  max-width: 380px;
  height: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 24px 0 rgba(138, 145, 156, 0.2);
  background-color: #ffffff;
  margin-right: ${props => (props.marginright ? "40px" : "0px")};
  `}
  ${Responsive.md`
  margin-top: 20px;
  `}
  @media (max-width: 950px) {
    height: 100%;
    margin-top: 20px;
    margin-right: ${props => (props.marginright ? "0px" : "0px")};
  }
  @media (max-width: 769px) {
    margin-top: 20px;
    width: 100%;
    margin-right: ${props => (props.marginright ? "0px" : "0px")};
  }
`
const StyledText = styled(Text)`
  text-align: left;
  margin-top: 10px;
  @media (max-width: 950px) {
    text-align: center;
  }
`

const PodcastGrid = styled.div`
  ${Responsive.sm`
  margin-top: 25px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  `}
  ${Responsive.lg`
  display: grid;
  margin-top: 50px;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  `}
  ${Responsive.xlg`
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  `}
`

const TestimonialBox = styled.div`
  width: 100%;
  height: auto;
  border-radius: 12px;
  padding: 50px;
  box-shadow: 0 8px 24px 0 rgba(138, 145, 156, 0.2);
  background-color: ${Colors.darkBlue};
  margin-right: ${props => (props.marginright ? "40px" : "0px")};
  @media (max-width: 950px) {
    height: auto;
    margin-top: 20px;
    margin-right: ${props => (props.marginright ? "0px" : "0px")};
  }
  @media (max-width: 769px) {
    margin-top: 20px;
    width: 100%;
    margin-right: ${props => (props.marginright ? "0px" : "0px")};
    max-width: 780px;
  }
`

const TestimonialBox2 = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: ${Colors.darkBlue};
  border-radius: 7px;
  padding: 30px;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  gap: 25px;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, 320px);
  }
`

const UserOval = styled.div`
  margin-right: 16px;
  @media (max-width: 1200px) {
    margin-bottom: 10px;
    margin-right: 0px;
  }
`

const PersonRow = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 0 10px 0;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
  }
`

export const Testimonials = ({ type }) => {
  const site = useStaticQuery(graphql`
    query {
      cory: file(relativePath: { eq: "testimonials/cory.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 150
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      bradey: file(relativePath: { eq: "testimonials/bradey.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      cristy: file(relativePath: { eq: "testimonials/cristyllt.jpeg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            height: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      albany: file(relativePath: { eq: "testimonials/crb.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 350
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `)

  const data = [
    {
      name: "Cory Iffert",
      title: "CEO of Iffert Media",
      img: site.cory.childImageSharp.gatsbyImageData,
      comment: `"I hired CheshTech to help overhaul our website. Will has a great eye for design and will make sure everything is exactly how you want it. He was very patient with my revisions throughout the process. I'm happy to continue working with him and recommend him to all of my clients!"`,
      oval: true,
    },
    {
      name: "Bradey Krone",
      title: "Co-Founder of Interior Motives Flooring Inc.",
      img: site.bradey.childImageSharp.gatsbyImageData,
      comment: `"Will with CheshTech did more than I could have asked for with our website build. He met deadlines, came up with new ideas and was quick to respond to all of my inquiries every step of the way. The process and price was better than what I had expected as well. We are already seeing results within the first week after launch. I will definitely recommend to other companies."`,
      oval: true,
    },
    {
      name: "Cristy Holt",
      title: "Marketing Manager and Real Estate Agent at Living Loving Team",
      img: site.cristy.childImageSharp.gatsbyImageData,
      comment: `"We love how responsive and result oriented CheshTech has been. Quick to meet your needs, easy to understand (as a layman), and friendly. You can’t go wrong working with this business!!"`,
      oval: true,
    },
  ]

  const podcastData = [
    {
      name: "-ChrisyT01 (Apple Podcasts)",
      comment: `"Intriguing, optimistic, and focused on positive solutions
      I found this podcast recently when I was looking for something to have on in the background while I was at work. I usually am more the true crime comedy podcast kinda person but I thought it would nice to have something more educational to break it up a bit. The host of this podcast Will Cheshier, or “The Chesh”, is concise and clear in his communication and keeps the conversation interesting with his guests and gives them the floor to really explain what their solution is. It’s a cool and easy way to learn about the creative solutions that people are coming up with to help improve problems they’re seeing. It’s nice to have a resource that focuses on the more positive aspect of figuring life out rather than the negatives. Overall, this is a great podcasts to listen to if you’re looking to find some more positivity and learn more about what people are doing to actually help solve the problems in the world with a host who is optimistic and keeps you engaged."`,
      alt: "workout",
    },
    {
      name: "-TheFarren",
      comment: `"My new favorite pod!
      Intriguing and fun listen for anyone regardless of background. Always so interesting to hear what the guests have to say. Love that the host is highlighting all the creativity out there amongst Entrepreneurs."`,
      alt: "workout",
    },
  ]

  const landingData = [
    {
      name: "Cory Iffert",
      title: "CEO of Iffert Media",
      img: site.cory.childImageSharp.gatsbyImageData,
      comment: `"I hired CheshTech to help overhaul our website. Will has a great eye for design and will make sure everything is exactly how you want it. He was very patient with my revisions throughout the process. I'm happy to continue working with him and recommend him to all of my clients!"`,
      oval: true,
    },
    {
      name: "Cristy Holt",
      title: "Marketing Manager and Real Estate Agent at Living Loving Team",
      img: site.cristy.childImageSharp.gatsbyImageData,
      comment: `"We love how responsive and result oriented CheshTech has been. Quick to meet your needs, easy to understand (as a layman), and friendly. You can’t go wrong working with this business!!"`,
      oval: true,
    },
    {
      name: "Bradey Krone",
      title: "Co-Founder of Interior Motives Flooring Inc.",
      img: site.bradey.childImageSharp.gatsbyImageData,
      comment: `"Will with CheshTech did more than I could have asked for with our website build. He met deadlines, came up with new ideas and was quick to respond to all of my inquiries every step of the way. The process and price was better than what I had expected as well. We are already seeing results within the first week after launch. I will definitely recommend to other companies."`,
      oval: true,
    },
  ]

  return (
    <Wrapper white>
      <Container>
        <Column alignitems="center">
          {type !== "landingpage" && type !== "podcast" && (
            <SEOTitle darkBlue>Testimonials</SEOTitle>
          )}
          {type === "podcast" && (
            <SEOTitle darkBlue>What Our Listeners Say</SEOTitle>
          )}
          <Grid>
            {type !== "landingpage" &&
              type !== "podcast" &&
              data.map((key, index) => {
                const image = getImage(key.img)
                return (
                  <TestimonialBox key={index}>
                    <Column>
                      <PersonRow>
                        <UserOval>
                          {key.oval === false && (
                            <GatsbyImage
                              image={image}
                              alt="CheshTech William Cheshier"
                              placeholder="blurred"
                            />
                          )}
                          {key.oval === true && (
                            <GatsbyImage
                              image={image}
                              alt="CheshTech William Cheshier"
                              placeholder="blurred"
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                        </UserOval>
                        <Column width="100%">
                          <Text white fontweight="bold">
                            {key.name}
                          </Text>
                          <Text textAlign="center" white fontweight="bold">
                            {key.title}
                          </Text>
                        </Column>
                      </PersonRow>
                      <Text white>{key.comment}</Text>
                    </Column>
                  </TestimonialBox>
                )
              })}
            {type === "landingpage" &&
              landingData.map((key, index) => {
                const image = getImage(key.img)
                return (
                  <TestimonialBox key={index}>
                    <Column>
                      <PersonRow>
                        <UserOval>
                          {key.oval === false && (
                            <GatsbyImage
                              image={image}
                              alt="CheshTech William Cheshier"
                              placeholder="blurred"
                            />
                          )}
                          {key.oval === true && (
                            <GatsbyImage
                              image={image}
                              alt="CheshTech William Cheshier"
                              placeholder="blurred"
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                        </UserOval>
                        <Column width="100%">
                          <Text white fontweight="bold">
                            {key.name}
                          </Text>
                          <Text textAlign="center" white fontweight="bold">
                            {key.title}
                          </Text>
                        </Column>
                      </PersonRow>
                      <Text white>{key.comment}</Text>
                    </Column>
                  </TestimonialBox>
                )
              })}
            {type === "serviceLanding" &&
              data.map((key, index) => {
                const image = getImage(key.img)
                return (
                  <TestimonialBox2>
                    <Row alignitems="flex-start" key={index}>
                      <Column width="100%">
                        <UserOval>
                          {key.oval === false && (
                            <GatsbyImage
                              image={image}
                              alt="CheshTech William Cheshier"
                              placeholder="blurred"
                            />
                          )}
                          {key.oval === true && (
                            <GatsbyImage
                              image={image}
                              alt="CheshTech William Cheshier"
                              placeholder="blurred"
                              style={{ borderRadius: "50%" }}
                            />
                          )}
                        </UserOval>
                      </Column>
                      <Column>
                        <Subtitle white>{key.name}</Subtitle>
                        <Text white margin="10px">
                          {key.comment}
                        </Text>
                      </Column>
                    </Row>
                  </TestimonialBox2>
                )
              })}
          </Grid>
          <PodcastGrid>
            {type === "podcast" &&
              podcastData.map((key, index) => {
                return (
                  <PodcastBox marginright key={index}>
                    <Column margin="32px">
                      <div>
                        <StyledText lineheight>{key.comment}</StyledText>
                        <Text
                          fontweight="bold"
                          textalign="center"
                          margin="10px 0 2px 0"
                        >
                          {key.name}
                        </Text>
                      </div>
                    </Column>
                  </PodcastBox>
                )
              })}
          </PodcastGrid>
        </Column>
      </Container>
    </Wrapper>
  )
}
