import React, { Fragment, useState } from "react"
import styled from "styled-components"
import { Modal, Mailchimp } from "../components/index"
import {
  Title,
  Button,
  Subtitle,
  Text,
  Column,
  Row,
  Container,
  SEOTitle,
} from "../theme/index"
import { Responsive, Colors } from "../theme/styles"
import { graphql, useStaticQuery, Link } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const StyledImg = styled(BgImage)`
  height: ${props => props.height || "640px"};
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &::after {
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 720px) {
    height: 600px;
    justify-content: center;
  }
`

const Background = styled.div`
  height: ${props => props.height || "640px"};
  display: flex;
  margin: 0 auto;
  justify-content: center;
  background: ${Colors.darkBlue};
  // align-items: center;
  flex-direction: column;
  padding: ${props => props.padding};
  &::after {
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 720px) {
    height: ${props => props.height || "600px"};
    justify-content: center;
  }
`

const SuccessBackground = styled.div`
  height: ${props => (props.freebie ? "100%" : "100vh")};
  display: flex;
  margin: 0 auto;
  justify-content: center;
  background: ${Colors.darkBlue};
  // align-items: center;
  flex-direction: column;
  padding: ${props => props.padding || "0"};
  &::after {
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 1023px) {
    height: 100%;
    justify-content: center;
    padding: ${props => props.padding || "50px 0"};
  }
`

const Grid = styled.div`
  ${Responsive.sm`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => (props.margintop ? "50px" : 0)};
  padding: ${props => props.padding || "0"};
  `}
  ${Responsive.lg`
  grid-template-columns: repeat(2, 50%);
  width: 100%;
  display: grid;
  grid-gap: 50px;
  align-items: center;
  padding: ${props => props.padding || "0"};
  `}
`

const StyledSubtitle = styled(Subtitle)`
  ${Responsive.sm`
text-align: ${props => props.textAlign || "center"};
font-size: ${props => (props.fontsize ? "20px" : "18px")};
`}
  ${Responsive.lg`
  text-align: left;
  font-size: ${props => (props.fontsize ? "44px" : "32px")};
  `}
`

const FreebieColumn = styled(Column)`
  ${Responsive.sm`
    width: 70%;
    margin-bottom: 50px;
  `}
  ${Responsive.md`
    width: 90%;
`}
  ${Responsive.lg`
    margin-bottom: 0px;
  `}
  ${Responsive.xlg`
  width: 70%;
  align-items: center;
  margin: 0 auto;
  `}
`

const StyledColumn = styled(Column)`
  width: 100%;
  align-items: center;
  margin-top: 0px;
  @media (max-width: 920px) {
    margin: ${props => (props.margintop ? "60px auto 0 auto" : "0 auto")};
  }
`

const LandingColumn = styled(Column)`
  ${Responsive.sm`
width: ${props => (props.checklist ? "75%" : "auto")};
align-items: center;
margin-bottom: 30px;
`}
  ${Responsive.md`
width: ${props => (props.checklist ? "50%" : "auto")};
align-items: center;
margin-bottom: 30px;
`}
  ${Responsive.lg`
  width: ${props => (props.checklist ? "auto" : "auto")};
  align-items: left;
  margin-bottom: 0px;
`}
`

const WillColumn = styled(Column)`
  ${Responsive.sm`
width: 40%;
align-items: center;
margin-bottom: 30px;
`}
  ${Responsive.md`
width: ${props => (props.width ? "25%" : "40%")};
`}
  ${Responsive.lg`
  width: 60%;
  align-items: center;
  margin: 0 auto;
`}
`

const TextColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 90%;
  @media (max-width: 1023px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`

const StyledTitle = styled(Title)`
  ${Responsive.sm`
margin: 0;
color: ${Colors.white};
text-align: center;
font-size: ${props => (props.fontsize ? "28px" : "24px")};
`}
  ${Responsive.lg`
text-align: left;
font-size: ${props => (props.fontsize ? "44px" : "36px")};
`}
`

const StyledButton = styled(Button)`
  ${Responsive.sm`
margin-right: 0px;
margin-bottom: 10px;
`}
  ${Responsive.lg`
margin-right: 10px;
margin-bottom: 0px;
`}
`

const ButtonContainer = styled(Row)`
  ${Responsive.sm`
justify-content: ${props => (props.justifycontent ? "center" : "center")};
align-items: center;
flex-direction: column;
`}
  ${Responsive.lg`
flex-direction: row;
justify-content: ${props => (props.justifycontent ? "flex-start" : "center")};
align-items: center;
`}
`

const StyledContainer = styled(Container)`
  ${Responsive.sm`
    padding: 0; 
  `}
  ${Responsive.lg`
  padding: 125px 0;
  `}
`

const StyledLi = styled.li`
  color: ${Colors.white};
  font-size: 18px;
`

const StyledText = styled(Text)`
  margin: 34px 0;
  color: ${Colors.white};
  font-size: 18px;
`

export const Splash = ({ title, subtitle, text, type }) => {
  const [show, setShow] = useState(false)

  const toggleModal = () => {
    setShow(!show)
  }

  const hero = useStaticQuery(graphql`
    query {
      home: file(relativePath: { eq: "hero/cheshtechheroimage.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      landing: file(relativePath: { eq: "hero/landingpagebackground.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      branding: file(relativePath: { eq: "hero/brandinghero.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      marketing: file(relativePath: { eq: "hero/marketinghero.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      webdev: file(relativePath: { eq: "hero/websitehero.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      app: file(relativePath: { eq: "hero/appheroimage.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const homeHero = getImage(hero.home.childImageSharp.gatsbyImageData)
  const landingHero = getImage(hero.landing.childImageSharp.gatsbyImageData)
  const brandHero = getImage(hero.branding.childImageSharp.gatsbyImageData)
  const websiteHero = getImage(hero.webdev.childImageSharp.gatsbyImageData)
  const appHero = getImage(hero.app.childImageSharp.gatsbyImageData)
  const marketingHero = getImage(hero.marketing.childImageSharp.gatsbyImageData)

  return (
    <Fragment>
      {type === "home" && (
        <StyledImg type={type} image={homeHero}>
          <Container>
            <StyledColumn margintop>
              <Title margin="0" white splash textAlign="center">
                {title}
              </Title>
              <Subtitle margin="20px 0 20px 0" white>
                {subtitle}
              </Subtitle>
              <ButtonContainer>
                <Link to="/schedule-consultation">
                  <StyledButton>Book Consultation</StyledButton>
                </Link>
                <Link to="/resources">
                  <Button>Free Resources</Button>
                </Link>
              </ButtonContainer>
            </StyledColumn>
          </Container>
        </StyledImg>
      )}
      {type === "onepage" && (
        <Background height="700px">
          <Container>
            <Grid>
              <LandingColumn checklist>
                <StaticImage
                  src="../images/landinglaptop.png"
                  alt="CheshTech Online Business checklist"
                  placeholder="blurred"
                  style={{
                    borderRadius: "5px",
                  }}
                />
              </LandingColumn>
              <TextColumn>
                <StyledTitle fontsize>{title}</StyledTitle>
                <StyledSubtitle fontsize margin="44px 0 44px 0" white>
                  {subtitle}
                </StyledSubtitle>
                <Link to="/contact">
                  <Button background={Colors.lightBlue}>
                    Book Consultation
                  </Button>
                </Link>
              </TextColumn>
            </Grid>
          </Container>
        </Background>
      )}
      {type === "consultation" && (
        <Background height="700px">
          <Container>
            <Grid>
              <WillColumn>
                <StaticImage
                  src="../images/ctawill.png"
                  alt="CheshTech CEO Will Cheshier"
                  placeholder="blurred"
                  style={{
                    borderRadius: "5px",
                    // height: "400px",
                    // width: "310px",
                  }}
                />
              </WillColumn>
              <TextColumn>
                <StyledTitle fontsize>{title}</StyledTitle>
                <StyledSubtitle margin="44px 0 44px 0" white>
                  {subtitle}
                </StyledSubtitle>
                <Link to="/contact">
                  <Button background={Colors.lightBlue}>
                    Book Consultation
                  </Button>
                </Link>
              </TextColumn>
            </Grid>
          </Container>
        </Background>
      )}
      {type === "freebie" && (
        <SuccessBackground freebie>
          <StyledContainer>
            <Column margin="0 0 50px 0">
              <Title white margin="0" splash>
                Get access to our exclusive website guide
              </Title>
            </Column>
            <Grid>
              <FreebieColumn>
                <StaticImage
                  src="../images/content/freebie.png"
                  alt="CheshTech CEO Will Cheshier"
                  placeholder="blurred"
                  style={{
                    borderRadius: "5px",
                    // height: "400px",
                    // width: "310px",
                  }}
                />
              </FreebieColumn>
              <TextColumn>
                <SEOTitle white margin="0" fontsize>
                  {title}
                </SEOTitle>
                <StyledText>
                  Looking to capture more leads from your website? We have the
                  tool for you! Find out 5 key elements you need to have on your
                  website and get real examples with this exclusive CheshTech
                  guide.
                </StyledText>
                <ul>
                  <StyledLi>Optimize your header for conversions</StyledLi>
                  <StyledLi>Outline a plan for the website visitor</StyledLi>
                  <StyledLi>
                    Implement these strategies to boost your conversions
                  </StyledLi>
                </ul>
                <ButtonContainer justifycontent>
                  <Button
                    background={Colors.lightBlue}
                    onClick={() => toggleModal()}
                  >
                    Access Now
                  </Button>
                </ButtonContainer>
              </TextColumn>
            </Grid>
            <Modal show={show} toggleModal={toggleModal} height="auto">
              <Mailchimp type="form" />
            </Modal>
          </StyledContainer>
        </SuccessBackground>
      )}
      {type === "success" && (
        <SuccessBackground>
          <Container>
            <Grid>
              <WillColumn width>
                <StaticImage
                  src="../images/ctawill.png"
                  alt="CheshTech CEO Will Cheshier"
                  placeholder="blurred"
                  style={{
                    borderRadius: "5px",
                    // height: "400px",
                    // width: "310px",
                  }}
                />
                <Text white margin="10px 0 0 0">
                  CheshTech CEO: Will Cheshier
                </Text>
              </WillColumn>
              <TextColumn>
                <StyledTitle fontsize>{title}</StyledTitle>
                <StyledSubtitle margin="44px 0 44px 0" white>
                  {subtitle}
                </StyledSubtitle>
                <ButtonContainer justifycontent>
                  <Link to="/schedule-consultation">
                    <StyledButton background={Colors.lightBlue}>
                      Book Consultation
                    </StyledButton>
                  </Link>
                  <Link to="/">
                    <Button background={Colors.lightBlue}>Go Home</Button>
                  </Link>
                </ButtonContainer>
              </TextColumn>
            </Grid>
          </Container>
        </SuccessBackground>
      )}
      {type === "landingPage" && (
        <StyledImg height="700px" type={type} image={landingHero}>
          <Container>
            <Grid margintop>
              <LandingColumn alignitems>
                <StyledTitle>{title}</StyledTitle>
                <StyledSubtitle margin="20px 0 10px 0" white>
                  {subtitle}
                </StyledSubtitle>
              </LandingColumn>
              <LandingColumn checklist>
                <StaticImage
                  src="../images/onlinebiz.png"
                  alt="CheshTech Online Business checklist"
                  placeholder="blurred"
                  //   layout="fixed"
                  width={400}
                  height={500}
                  style={{
                    boxShadow: "10px 10px 75px #fff",
                    borderRadius: "5px",
                  }}
                />
              </LandingColumn>
            </Grid>
          </Container>
        </StyledImg>
      )}
      {type === "branding" && (
        <StyledImg type={type} image={brandHero}>
          <Container>
            <StyledColumn>
              <Title margin="0" white splash textAlign="center">
                {title}
              </Title>
            </StyledColumn>
          </Container>
        </StyledImg>
      )}
      {type === "marketing" && (
        <StyledImg type={type} image={marketingHero}>
          <Container>
            <StyledColumn>
              <Title margin="0" white splash textAlign="center">
                {title}
              </Title>
            </StyledColumn>
          </Container>
        </StyledImg>
      )}
      {type === "webdev" && (
        <StyledImg type={type} image={websiteHero}>
          <Container>
            <StyledColumn>
              <Title margin="0" white splash textAlign="center">
                {title}
              </Title>
            </StyledColumn>
          </Container>
        </StyledImg>
      )}
      {type === "app" && (
        <StyledImg type={type} image={appHero}>
          <Container>
            <StyledColumn>
              <Title margin="0" white splash textAlign="center">
                {title}
              </Title>
            </StyledColumn>
          </Container>
        </StyledImg>
      )}
    </Fragment>
  )
}
