import React from "react"
import styled from "styled-components"
import { Title, Column, Container } from "../theme/index"
import { graphql, useStaticQuery } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"

const StyledImg = styled(BgImage)`
  height: 250px;
  display: flex;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  &::after {
    background-position: center;
    background-size: contain;
  }
  @media (max-width: 720px) {
    height: 250px;
    justify-content: center;
  }
`

const StyledColumn = styled(Column)`
  width: 100%;
  align-items: center;
  margin-top: 50px;
`

export const HeroSplash = ({ title, type }) => {
  const { placeholderImage } = useStaticQuery(
    graphql`
      query {
        placeholderImage: file(relativePath: { eq: "hero/smallhero.png" }) {
          childImageSharp {
            gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
          }
        }
      }
    `
  )

  const pluginImage = getImage(placeholderImage)

  return (
    <StyledImg image={pluginImage} type={type}>
      <Container>
        <StyledColumn>
          <Title margin="0" white splash>
            {title}
          </Title>
        </StyledColumn>
      </Container>
    </StyledImg>
  )
}
