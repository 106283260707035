import React, { useState } from "react"
import styled from "styled-components"
import { Tooltip } from "./tooltip"
import { Column, Subtitle, SEOTitle, Text, Row } from "../theme"
import { IoIosSpeedometer } from "react-icons/io"
import { Colors, Responsive } from "../theme/styles"
import {
  GrTarget,
  GrCircleQuestion,
  GrClock,
  GrUserExpert,
} from "react-icons/gr"
import { GiSaveArrow } from "react-icons/gi"
import { AiOutlineHeart } from "react-icons/ai"
import { MdColorLens, MdPeople, MdEmail } from "react-icons/md"

const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  margin: 30px 0 0px 0;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
`

const StyledText = styled(Text)`
  ${Responsive.sm`
    margin-right: 0px;
    margin-bottom: 5px;
`}
  ${Responsive.lg`
    margin-right: 5px;
    margin-bottom: 0px;
`}
`

const StyledRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: column;
    align-items: center;
`}
  ${Responsive.lg`
    flex-direction: row;
`}
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
    margin-top: ${props => (props.margintop ? "30px" : 0)};
`}
  ${Responsive.md`
  margin-top: ${props => (props.margintop ? "0px" : 0)};
`}
`

export const IconRow = ({ type }) => {
  const [show, setShow] = useState(false)

  const webIcons = [
    {
      icon: <IoIosSpeedometer size={100} color={Colors.darkBlue} />,
      subtitle: "3x Faster",
      message:
        "The time a user has to wait before they can interact with a page.",
      text: "Time to Interactive",
    },
    {
      icon: <GrTarget size={100} color={Colors.darkBlue} />,
      subtitle: "      Up to 30% increase in conversions",
      message: "The time a user has to wait before the page looks finished.",
      text: "Speed Index",
    },
    {
      icon: <GiSaveArrow size={100} color={Colors.darkBlue} />,
      subtitle: "90% smaller",
      message:
        "The total file size of the web page including images, videos, fonts, and code.",
      text: "Page Size",
    },
  ]

  const appIcons = [
    {
      icon: <GrClock size={100} color={Colors.black} />,
      subtitle: "Save Time and Costs",
      text: "Outsourcing your product work is cheaper and saves your business time",
    },
    {
      icon: <GrUserExpert size={100} color={Colors.black} />,
      subtitle: "Access to Our Team of Experts",
      text: "Work directly with our experienced CTO on your Projects",
    },
    {
      icon: <IoIosSpeedometer size={100} color={Colors.black} />,
      subtitle: "Get Products to Market Faster",
      text: "Utilize our resources and we'll take your project from start to finish quickly and efficiently",
    },
  ]

  const brandingIcons = [
    {
      icon: <MdPeople size={100} color={Colors.darkBlue} />,
      subtitle:
        "On average it takes 5-7 impressions for a user to recognize your brand",
    },
    {
      icon: <AiOutlineHeart size={100} color={Colors.darkBlue} />,
      subtitle: "89% of shoppers stay loyal to brands that share their values",
    },
    {
      icon: <MdColorLens size={100} color={Colors.darkBlue} />,
      subtitle: "Color improves brand recognition up to 80%",
    },
  ]

  const marketingIcons = [
    {
      icon: <MdPeople size={100} color={Colors.darkBlue} />,
      subtitle:
        "Increase traffic to your website through a proper salees funnel approach",
    },
    {
      icon: <AiOutlineHeart size={100} color={Colors.darkBlue} />,
      subtitle: "Build a trusted brand through a strong marketing strategy",
    },
    {
      icon: <MdEmail size={100} color={Colors.darkBlue} />,
      subtitle: "Keep your customers engaged through strong email marketing",
    },
  ]

  return (
    <Column margin="25px 0 100px 0">
      {type === "website" && (
        <SEOTitle textAlign="center">
          We Specialize in Building Lightning Fast Websites That Generate Leads
        </SEOTitle>
      )}
      {type === "branding" && (
        <SEOTitle textAlign="center">
          Providing Our Clients The Branding They Deserve
        </SEOTitle>
      )}
      {type === "app" && (
        <SEOTitle textAlign="center">
          We Turn Your Product Dreams Into Reality
        </SEOTitle>
      )}
      {type === "marketing" && (
        <SEOTitle textAlign="center">
          Creating Successful Marketing Funnels To Spur Your Growth
        </SEOTitle>
      )}
      <Grid>
        {type === "website" &&
          webIcons.map((key, index) => {
            return (
              <StyledColumn key={index}>
                {key.icon}
                <Subtitle textAlign="center">{key.subtitle}</Subtitle>
                <Tooltip type="icon" show={show} message={key.message}>
                  <StyledRow>
                    <StyledText textAlign="center" small>
                      {key.text}
                    </StyledText>
                    <GrCircleQuestion
                      size={20}
                      color={Colors.black}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(!show)}
                    />
                  </StyledRow>
                </Tooltip>
              </StyledColumn>
            )
          })}
        {type === "landingPageIcons" &&
          webIcons.map((key, index) => {
            return (
              <StyledColumn key={index}>
                {key.icon}
                <Subtitle textAlign="center">{key.subtitle}</Subtitle>
                <Tooltip type="icon" show={show} message={key.message}>
                  <StyledRow>
                    <StyledText textAlign="center" small>
                      {key.text}
                    </StyledText>
                    <GrCircleQuestion
                      size={20}
                      color={Colors.black}
                      style={{ cursor: "pointer" }}
                      onClick={() => setShow(!show)}
                    />
                  </StyledRow>
                </Tooltip>
              </StyledColumn>
            )
          })}
        {type === "app" &&
          appIcons.map((key, index) => {
            return (
              <StyledColumn key={index}>
                {key.icon}
                <Subtitle textAlign="center">{key.subtitle}</Subtitle>
                <StyledText textAlign="center" small>
                  {key.text}
                </StyledText>
              </StyledColumn>
            )
          })}
        {type === "branding" &&
          brandingIcons.map((key, index) => {
            return (
              <StyledColumn key={index}>
                {key.icon}
                <Subtitle textAlign="center">{key.subtitle}</Subtitle>
              </StyledColumn>
            )
          })}
        {type === "marketing" &&
          marketingIcons.map((key, index) => {
            return (
              <StyledColumn key={index}>
                {key.icon}
                <Subtitle textAlign="center">{key.subtitle}</Subtitle>
              </StyledColumn>
            )
          })}
      </Grid>
    </Column>
  )
}
