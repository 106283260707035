import React, { Fragment } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const BrandingWorkExamples = () => {
  const work = useStaticQuery(graphql`
    query {
      talkinsolutions: file(
        relativePath: { eq: "brandingexamples/talkinsolutionsgraphic.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      zippota: file(
        relativePath: { eq: "brandingexamples/logomoodboard.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      zippotabranding: file(
        relativePath: { eq: "brandingexamples/moodboard.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      nfd: file(
        relativePath: { eq: "brandingexamples/nfdschedulewebsite.png" }
      ) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const brandingImages = [
    {
      img: work.talkinsolutions.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Living Loving Team",
      type: "llt",
    },
    {
      img: work.zippota.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Zippota",
      type: "zippota",
    },
    {
      img: work.zippotabranding.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Zippota",
      type: "nfd",
    },
  ]
  return (
    <Fragment>
      {brandingImages.map((key, index) => {
        const image = getImage(key.img)
        return (
          <GatsbyImage
            image={image}
            alt={key.alt}
            placeholder="blurred"
            key={index}
          />
        )
      })}
    </Fragment>
  )
}
