import React from "react"
import styled from "styled-components"
import { SEOTitle, Wrapper, Container, Column } from "../theme/index"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
  width: 100%;
  margin-top: 30px;
  align-items: center;
  justify-items: center;
  @media (max-width: 1000px) {
    grid-template-columns: repeat(2, 1fr);
  }
  // @media (max-width: 750px) {
  //   grid-template-columns: repeat(1, 1fr);
  // }
`

export const Clients = () => {
  const logos = useStaticQuery(graphql`
    query {
      iffertmedia: file(relativePath: { eq: "clientwork/IFFERT-MEDIA.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      llt: file(relativePath: { eq: "clientwork/lltlogo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      achievece: file(relativePath: { eq: "clientwork/achievelogo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      athena: file(relativePath: { eq: "clientwork/athenalogo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      solutionshouse: file(relativePath: { eq: "clientwork/shlogo.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 250
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      zippota: file(relativePath: { eq: "clientwork/fulllogozippota.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      interior: file(relativePath: { eq: "clientwork/interiorlogo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 250
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      nfd: file(relativePath: { eq: "clientwork/nfdlogo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 200
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `)

  const data = [
    {
      logo: logos.iffertmedia.childImageSharp.gatsbyImageData,
    },
    {
      logo: logos.llt.childImageSharp.gatsbyImageData,
    },
    {
      logo: logos.zippota.childImageSharp.gatsbyImageData,
    },
    {
      logo: logos.achievece.childImageSharp.gatsbyImageData,
    },
    {
      logo: logos.solutionshouse.childImageSharp.gatsbyImageData,
    },
    {
      logo: logos.nfd.childImageSharp.gatsbyImageData,
    },
    {
      logo: logos.interior.childImageSharp.gatsbyImageData,
    },
    {
      logo: logos.athena.childImageSharp.gatsbyImageData,
    },
  ]

  return (
    <Wrapper>
      <Container>
        <Column>
          <SEOTitle darkBlue>Clients who trust us</SEOTitle>
          <Grid>
            {data.map((key, index) => {
              const image = getImage(key.logo)
              return (
                <GatsbyImage
                  image={image}
                  alt="CheshTech William Cheshier"
                  placeholder="blurred"
                  key={index}
                />
              )
            })}
          </Grid>
        </Column>
      </Container>
    </Wrapper>
  )
}
