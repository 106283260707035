import { css } from "styled-components"

export const Colors = {
  lightBlue: "#4ca1af",
  darkBlue: "#2c3e50",
  white: "#ffffff",
  black: "#000000",
}

export const Fonts = {
  raleway: `Raleway, sans-serif`,
  // montserrat: 'Playfair Display, serif',
  montserrat: `Montserrat, sans-serif`,
}

export const Responsive = {
  sm: (...args) => css`
    @media (min-width: 320px) {
      ${css(...args)};
    }
  `,
  md: (...args) => css`
    @media (min-width: 767px) {
      ${css(...args)};
    }
  `,
  lg: (...args) => css`
    @media (min-width: 1024px) {
      ${css(...args)};
    }
  `,
  xlg: (...args) => css`
    @media (min-width: 1400px) {
      ${css(...args)};
    }
  `,
}
