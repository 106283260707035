import React, { useState, Fragment } from "react"
import { Subtitle, Text, Column } from "../theme/index"
import { Modal } from "./modal"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

export const ProductWorkExamples = () => {
  const [show, setShow] = useState(false)
  const [type, setType] = useState("")

  const work = useStaticQuery(graphql`
    query {
      parakeet: file(relativePath: { eq: "clientwork/flyparakeet.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      achievece: file(relativePath: { eq: "clientwork/achievelogo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      athena: file(relativePath: { eq: "clientwork/athenalogo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const productWork = [
    {
      img: work.parakeet.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Parakeet",
      type: "parakeet",
      link: "/work/case-study-parakeet",
    },
    {
      img: work.achievece.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: AchieveCE",
      type: "achievece",
      link: "/work/case-study-achievece",
    },
    {
      img: work.athena.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Athena Alliance",
      type: "athena",
    },
  ]

  const toggleModal = key => {
    setShow(!show)
    if (key.type === "parakeet") {
      setType("parakeet")
    } else if (key.type === "achievece") {
      setType("achievece")
    } else if (key.type === "athena") {
      setType("athena")
    } else {
      setType("parakeet")
    }
  }
  return (
    <Fragment>
      {productWork.map((key, index) => {
        const image = getImage(key.img)
        return (
          <Column>
            <Link to={key.link}>
              <GatsbyImage
                image={image}
                alt={key.alt}
                placeholder="blurred"
                key={index}
                style={{ cursor: "pointer" }}
              />
            </Link>
          </Column>
        )
      })}
      {type === "parakeet" && (
        <Modal show={show} toggleModal={toggleModal}>
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/clientwork/parakeetlogo.png"
              alt="CheshTech Application Development"
              placeholder="blurred"
              //   layout="fixed"
              width={500}
              height={250}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle small>Parakeet: A Product Tool</Subtitle>
            <Subtitle small>Monthly Sales Funnel/Marketing Consulting</Subtitle>
            <Text>
              <strong>Project:</strong> Monthly marketing consulting and task
              delegation. CheshTech LLC serves as a marketing consultant and
              functional director for the Living Loving Team assigning their
              marketing team tasks, generating content ideas, and strategizing
              their overall real estate marketing approach.
            </Text>
          </Column>
        </Modal>
      )}
      {type === "achievece" && (
        <Modal show={show} toggleModal={toggleModal}>
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/clientwork/achievelogo.png"
              alt="CheshTech Application Development and MVP Development Services"
              placeholder="blurred"
              //   layout="fixed"
              width={200}
              height={200}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle small>AchieveCE: Educational Platform</Subtitle>
            <Subtitle small>
              Monthly Social Media Management & Consulting
            </Subtitle>
            <Text>
              <strong>Project:</strong> NightFoxDude is a Twitch Streamer and
              Internet Personality and we inherited his profile essentially from
              scratch. We helped rebrand his character image, create valuable
              content across Instagram, YouTube, Twitch, and Facebook. Our work
              helped bring him to Twitch Affliate status where our client is
              currently working his way towards a Twitch Partner to bring in
              even more revenue.
            </Text>
          </Column>
        </Modal>
      )}
      {type === "athena" && (
        <Modal show={show} toggleModal={toggleModal}>
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/clientwork/athenalogo.png"
              alt="CheshTech MVP and Feature Development"
              placeholder="blurred"
              //   layout="fixed"
              width={250}
              height={150}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle small>Athena Alliance</Subtitle>
            <Subtitle small>
              Monthly Social Media Management & Consulting
            </Subtitle>
            <Text>
              <strong>Project:</strong> Starting from scratch, we took the
              Zippota Online Store and rebranded the company by touching up
              their logo, spicing up their colors, niching down to the LatinX
              community and creating content templates for use across social
              media including Facebook, Instagram, and Pinterest. A fresh
              project in its early stages, we've seen great results thus far and
              look forward to continue to work with Zippota.
            </Text>
          </Column>
        </Modal>
      )}
    </Fragment>
  )
}
