import React from "react"
import styled from "styled-components"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Wrapper, Column, SEOTitle, Subtitle } from "../theme"
import { Responsive } from "../theme/styles"
import { Mailchimp } from "../components/mailchimp"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 400px);
  gap: 75px;
  width: 100%;
  margin-top: 50px;
  justify-content: center;
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, 300px);
  }
`

const StyledText = styled(Subtitle)`
  ${Responsive.sm`
margin: 0 0 15px 0;
width: 100%;
`}
  ${Responsive.lg`
width: 75%;
`}
`

export const LeadMagnetCTA = () => {
  return (
    <Wrapper>
      <Container>
        <Column>
          <SEOTitle>Download Our Free Guide</SEOTitle>
          <Grid>
            <Column>
              <StaticImage
                src="../images/freebie.png"
                alt="CheshTech 5 key website elements"
                placeholder="blurred"
                //   layout="fixed"
              />
            </Column>
            <Column>
              <StyledText>
                5 key website elements to boost conversions and generate more
                leads
              </StyledText>
              <Mailchimp />
            </Column>
          </Grid>
        </Column>
      </Container>
    </Wrapper>
  )
}
