import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  SEOTitle,
  Button,
  Wrapper,
  Container,
  Text,
  Column,
} from "../theme/index"
import { Colors, Fonts, Responsive } from "../theme/styles"
import { FaLaptopCode, FaDraftingCompass, FaHashtag } from "react-icons/fa"

const ServiceCard = styled.div`
  ${Responsive.sm`
display: flex;
justify-content: center;
background: ${Colors.darkBlue};
align-items: center;
border: 2px solid ${Colors.lightBlue};
width: 100%;
height: 100%;
color: ${Colors.white};
margin-top: 30px;
padding: 20px;
margin-bottom: 30px;
font-family: ${Fonts.montserrat};
cursor: pointer;
margin-right: ${props => (props.marginRight ? "10px" : "0")};
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
transition: 0.3s;
&:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  background: ${Colors.white};
  color: ${Colors.black};
}
`}
  ${Responsive.lg`
margin-top: 0px;
margin-bottom: 0px;
`}
`

const ServicesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  @media (max-width: 769px) {
    display: block;
  }
`

const StyledLi = styled.li`
  margin-top: 10px;
  font-size: 16px;
`

const StyledText = styled(Text)`
  color: inherit;
  margin-top: 10px;
`

const data = [
  {
    icon: <FaDraftingCompass size={60} />,
    service: "Consulting",
    list1: "Direct access to our leadership team of experts",
    list2: "Available for hourly and retainer consultations",
    list3:
      "Sales funnel strategy, website audits, product roadmapping, social media marketing audits and digital marketing consultations",
    margin: true,
    link: "/services",
  },
  {
    icon: <FaLaptopCode size={60} />,
    service: "Website and Mobile App Design & Development",
    list1: "Modern websites to send you past the competition",
    list2:
      "Latest Technology: Headless CMS, React, Node.Js, Gatsby, WordPress, and Shopify",
    list3: "Product Design and Development",
    margin: true,
    link: "/services/website-design-development",
  },
  {
    icon: <FaHashtag size={60} />,
    service: "Digital Marketing",
    list1: "Social Media Marketing and Content Creation",
    list2: "Email Marketing Management and Creation",
    list3: "Creation of Sales Funnels",
    margin: true,
    link: "/services/website-design-development",
  },
]

export const ServicesHome = () => {
  return (
    <Wrapper lightBlue>
      <Container>
        <Column alignitems="center">
          <SEOTitle white margin="0px 0 20px 0" textAlign="center">
            Digital Strategies and Products To Grow Your Company
          </SEOTitle>

          <ServicesGrid>
            {data.map((key, index) => {
              return (
                <Link to={key.link}>
                  <ServiceCard marginRight key={index}>
                    <Column
                      alignitems="center"
                      justifycontent="center"
                      width="90%"
                      margin="0px auto"
                    >
                      {key.icon}
                      <StyledText textAlign="center">{key.service}</StyledText>
                      <ul>
                        <StyledLi>{key.list1}</StyledLi>
                        <StyledLi>{key.list2}</StyledLi>
                        <StyledLi>{key.list3}</StyledLi>
                      </ul>
                    </Column>
                  </ServiceCard>
                </Link>
              )
            })}
          </ServicesGrid>
          <Column margin="50px 0 0 0">
            <Link to="/schedule-consultation">
              <Button background={Colors.darkBlue} hover={Colors.black}>
                Book Consultation
              </Button>
            </Link>
          </Column>
        </Column>
      </Container>
    </Wrapper>
  )
}
