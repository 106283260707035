import React from "react"
import {
  FaDraftingCompass,
  FaNetworkWired,
  FaTools,
  FaLaptopCode,
  FaShopify,
  FaGlobe,
} from "react-icons/fa"
import { Colors } from "../theme/styles"

export const useMarketingServices = () => {
  const marketingData = [
    {
      icon: <FaLaptopCode size={60} color={Colors.darkBlue} />,
      text: "Social Media Marketing",
    },
    {
      icon: <FaNetworkWired size={60} color={Colors.darkBlue} />,
      text: "On-Page and Technical SEO",
    },
    {
      icon: <FaShopify size={60} color={Colors.darkBlue} />,
      text: "Email Marketing",
    },
    {
      icon: <FaDraftingCompass size={60} color={Colors.darkBlue} />,
      text: "Sales Funnel and Marketing Strategy Consulting",
    },
    {
      icon: <FaGlobe size={60} color={Colors.darkBlue} />,
      text: "Content Marketing",
    },
    {
      icon: <FaTools size={60} color={Colors.darkBlue} />,
      text: "Branding + Content Creation",
    },
  ]
  return marketingData
}
