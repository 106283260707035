import React from "react"
import styled from "styled-components"
import { FaTimes } from "react-icons/fa"
import { Row } from "../theme"
import { StaticImage } from "gatsby-plugin-image"
import { Responsive, Colors } from "../theme/styles"

// MODAL ANIMATION
const Backdrop = styled.div`
  ${Responsive.sm`
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow-y: scroll;
    background-color: rgba(9, 9, 9, 0.8);
    z-index: 100000;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 500ms;
`}
`

const Div = styled.div`
  ${Responsive.sm`
width: 90%;
height: ${props => props.height || "95%"};
border-radius: 20px;
background-color: #fff;
overflow-y: scroll;
right: 0;
bottom: 0;
padding: 20px;
z-index: 12;
`}
  ${Responsive.md`
width: 600px;
`}
  ${Responsive.lg`
width: 700px;
height: auto;
`}
`

const StyledClose = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 32px;
  height: 32px;
  background-color: #f2f5f9;
  border-radius: 50%;
  cursor: pointer;
  transition: 500ms;
  &:hover {
    background-color: ${Colors.lightBlue};
  }
`

export const Modal = ({ show, children, toggleModal, height }) => {
  if (!show) {
    return null
  }
  return (
    <Backdrop>
      <Div height={height}>
        <Row justifycontent="space-between" margin="20px" alignitems="center">
          <StaticImage
            src="../images/CheshTechLogoIconblue.png"
            alt="CheshTech Digital Agency: Increasing leads through conversion rate optimization"
            placeholder="blurred"
            //   layout="fixed"
            width={50}
            height={50}
            style={{ borderRadius: "50%" }}
          />
          <StyledClose type="button" onClick={toggleModal}>
            <FaTimes size={20} />
          </StyledClose>
        </Row>
        {children}
      </Div>
    </Backdrop>
  )
}
