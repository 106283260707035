import React, { Fragment } from "react"
import styled from "styled-components"
import { ButtonContainer } from "./index"
import {
  SEOTitle,
  Wrapper,
  Container,
  Row,
  Text,
  Column,
  Button,
} from "../theme/index"
import { Responsive, Colors } from "../theme/styles"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

const DynamicWrapper = styled(Wrapper)`
  background: ${props => props.background || `${Colors.white}`};
`

const AboutRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
  `}
  ${Responsive.lg`
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
    margin-top: 0px;
  `}
`

const Video = styled.iframe`
  ${Responsive.sm`
  width: 100%;
  margin: 100px auto 0px auto;
  height: 300px;
`}
  ${Responsive.lg`
width: 50%;
height: 400px;
`}
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
    align-items: center;
    width: ${props => props.width || "100%"};
    margin: ${props =>
      props.margin ? "20px 0px 0 0" : props.top ? "10px 0 10px 0" : "0"};
  `}
  ${Responsive.lg`
  width: ${props => props.width || "100%"};
    margin: ${props =>
      props.margin ? "0px 20px 0 0" : props.top ? "20px 0 0 0" : "0"};
    
`}
`

const StyledText = styled(Text)`
  color: ${props => props.color || `${Colors.black}`};
  margin: ${props => (props.margintop ? "15px auto" : "0")};
`
const StyledSubtitle = styled(SEOTitle)`
  color: ${props => props.color || `${Colors.darkBlue}`};
  text-align: center;
  margin-bottom: 10px;
`

export const TwoColumns = ({
  type,
  paragraph1,
  paragraph2,
  title,
  homeTitle,
  background,
  color,
}) => {
  return (
    <DynamicWrapper background={background}>
      <Container>
        {type === "home" && (
          <SEOTitle textAlign="center" darkBlue margin="0 auto 10px auto">
            {homeTitle}
          </SEOTitle>
        )}
        {type === "homePitch" && (
          <Column>
            <SEOTitle textAlign="left">
              We propel companies forward by turning your website into a 24/7
              sales and marketing machine
            </SEOTitle>
            <Video
              src="https://www.youtube.com/embed/tmvP-k2cRjg"
              loading="lazy"
              srcDoc="<style>*{padding:0;margin:0;overflow:hidden;color:#fff;}html,body{height:100%}img,span{position:absolute;width:100%;top:0;bottom:0;margin:auto}span{height:1.5em;text-align:center;font:48px/1.5 sans-serif;color:white;text-shadow:0 0 0.5em black}</style><a href=https://www.youtube.com/embed/tmvP-k2cRjg><img src=https://img.youtube.com/vi/tmvP-k2cRjg/hqdefault.jpg alt='CheshTech Digital Agency: Increasing our clients' revenue through modern websites and digital marketing>]<span>▶</span></a>"
              title="CheshTech Intro"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              frameBorder="0"
              webkitallowfullscreen="true"
              mozallowfullscreen="true"
              allowFullScreen
            />
            <Link to="/schedule-consultation">
              <Button margin="30px 0 50px 0">Book Consultation</Button>
            </Link>
          </Column>
        )}
        {type !== "homePitch" && (
          <AboutRow>
            <StyledColumn margin>
              <Fragment>
                <Row>
                  <StyledSubtitle color={color}>{title}</StyledSubtitle>
                </Row>
                <StyledText color={color}>{paragraph1}</StyledText>
                <StyledText color={color} margintop>
                  {paragraph2}
                </StyledText>
                {type === "about" && (
                  <ButtonContainer
                    link="/schedule-consultation"
                    btnText="Book Consultation"
                    link2="/talkin-solutions-podcast"
                    btnText2="Go To Podcast"
                  />
                )}
                {type === "cto" && (
                  <a href="https://williamwhatley.io" target="__blank">
                    <Button margin="15px 0 0 0">Learn More</Button>
                  </a>
                )}
                {type === "marketing" && (
                  <Link to="/schedule-consultation">
                    <Button margin="15px 0 0 0">Book Consultation</Button>
                  </Link>
                )}
                {type === "homePage" && (
                  <ButtonContainer
                    link="/schedule-consultation"
                    btnText="Book Consultation"
                    link2="/talkin-solutions-podcast"
                    btnText2="Go to Podcast"
                  />
                )}
              </Fragment>
            </StyledColumn>
            <StyledColumn top>
              {type === "about" && (
                <Fragment>
                  <Column width="100%">
                    <StaticImage
                      src="../images/willaboutpage.jpg"
                      alt="CheshTech William Cheshier"
                      placeholder="blurred"
                      //   layout="fixed"
                      // width={400}
                      // height={600}
                    />
                  </Column>
                  <Text margin="5px 0 20px 0">
                    CheshTech Owner: William Cheshier
                  </Text>
                </Fragment>
              )}
              {type === "services" && (
                <StaticImage
                  src="../images/content/scoping.jpg"
                  alt="CheshTech Services"
                  placeholder="blurred"
                  //   layout="fixed"
                />
              )}
              {type === "cto" && (
                <Fragment>
                  <StaticImage
                    src="../images/team/whatley.jpg"
                    alt="CheshTech Services William Whatley"
                    placeholder="blurred"
                    style={{ borderRadius: "10px" }}
                    //   layout="fixed"
                  />
                  <Text white margin="5px 0 20px 0">
                    CheshTech CTO: William Whatley
                  </Text>
                </Fragment>
              )}
              {type === "marketing" && (
                <Fragment>
                  <StaticImage
                    src="../images/team/raquel.jpg"
                    alt="CheshTech Raquel Cespedes Diaz"
                    placeholder="blurred"
                    style={{ borderRadius: "10px" }}
                    width={400}
                    height={575}
                  />
                  <Text white margin="5px 0 20px 0">
                    CheshTech Marketing Director: Raquel Cespedes Diaz
                  </Text>
                </Fragment>
              )}
              {type === "homePage" && (
                <Fragment>
                  <StaticImage
                    src="../images/willpresenting.jpg"
                    alt="CheshTech Digital Agency: Modern websites, apps, and digital marketing"
                    placeholder="blurred"
                    style={{ borderRadius: "10px" }}
                  />
                </Fragment>
              )}
            </StyledColumn>
          </AboutRow>
        )}
      </Container>
    </DynamicWrapper>
  )
}
