import React from "react"
import {
  FaDraftingCompass,
  FaNetworkWired,
  FaTools,
  FaLaptopCode,
  FaGlobe,
  FaShopify,
} from "react-icons/fa"
import { Colors } from "../theme/styles"

export const useWebDevServices = () => {
  const webDevData = [
    {
      icon: <FaLaptopCode size={60} color={Colors.darkBlue} />,
      text: "Websites",
    },
    {
      icon: <FaNetworkWired size={60} color={Colors.darkBlue} />,
      text: "Headless CMS",
    },
    {
      icon: <FaShopify size={60} color={Colors.darkBlue} />,
      text: "E-Commerce",
    },
    {
      icon: <FaDraftingCompass size={60} color={Colors.darkBlue} />,
      text: "UX Strategy",
    },
    {
      icon: <FaGlobe size={60} color={Colors.darkBlue} />,
      text: "Progressive Web Applications",
    },
    {
      icon: <FaTools size={60} color={Colors.darkBlue} />,
      text: "Website Mainteance",
    },
  ]
  return webDevData
}
