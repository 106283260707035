import React from "react"
import {
  FaDraftingCompass,
  FaNetworkWired,
  FaTools,
  FaLaptopCode,
  FaGlobe,
  FaShopify,
} from "react-icons/fa"
import { Colors } from "../theme/styles"

export const useBrandingServices = () => {
  const brandingData = [
    {
      icon: <FaLaptopCode size={60} color={Colors.darkBlue} />,
      text: "Social Media Branding",
    },
    {
      icon: <FaNetworkWired size={60} color={Colors.darkBlue} />,
      text: "Template Creation",
    },
    {
      icon: <FaShopify size={60} color={Colors.darkBlue} />,
      text: "Logo and Graphic Designs",
    },
    {
      icon: <FaDraftingCompass size={60} color={Colors.darkBlue} />,
      text: "Branding Guidelines and Document",
    },
    {
      icon: <FaGlobe size={60} color={Colors.darkBlue} />,
      text: "Video Creation",
    },
    {
      icon: <FaTools size={60} color={Colors.darkBlue} />,
      text: "TBD",
    },
  ]
  return brandingData
}
