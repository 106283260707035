import React from "react"
import styled from "styled-components"
import { SEOTitle, Text, Wrapper, Column, Button } from "../theme/index"
import { graphql, useStaticQuery, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import { Colors } from "../theme/styles"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 500px);
  gap: 15px;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, 300px);
  }
`

const BoxWrapper = styled.div`
  position: relative;
  background: #fff;
  width: 100%;
  height: 100%;
  cursor: pointer;
`

const Box = styled.div`
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transition: 500ms ease;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  align-items: center;
  opacity: 0;
  position: absolute;
  &:hover {
    border: 1px solid ${Colors.lightBlue};
    opacity: 1;
    background-color: rgba(54, 60, 74, 0.9);
    box-shadow: 0 8px 24px 0 rgba(138, 145, 156, 0.2);
    margin: 0;
  }
`

const StyledWrapper = styled(Wrapper)`
  background: ${props => props.background};
  padding: ${props => props.padding};
`

const Title = styled(SEOTitle)`
  color: ${props => props.color};
`

export const Work = ({ type, padding }) => {
  const data = useStaticQuery(graphql`
    query {
      allWpPost(
        filter: {
          categories: { nodes: { elemMatch: { name: { eq: "case-study" } } } }
        }
        sort: { order: DESC, fields: date }
      ) {
        nodes {
          excerpt
          title
          uri
          slug
          date(formatString: "MM/DD/Y")
          featuredImage {
            node {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 800
                    height: 500
                    placeholder: TRACED_SVG
                  )
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <StyledWrapper
      type={type}
      padding={padding}
      background={
        type === "home"
          ? `${Colors.lightBlue}`
          : type !== "home"
          ? `${Colors.white}`
          : `${Colors.white}`
      }
    >
      <Column alignitems="center">
        <Title
          color={
            type === "home"
              ? `${Colors.white}`
              : type !== "home"
              ? `${Colors.black}`
              : `${Colors.black}`
          }
        >
          Recent Case Studies
        </Title>
        <Grid>
          {data.allWpPost.nodes.map(node => {
            const image = getImage(
              node.featuredImage.node.localFile.childImageSharp.gatsbyImageData
            )
            const url = `work/${node.slug}`
            return (
              <Link to={type === "home" ? url : `${node.slug}`}>
                <BoxWrapper>
                  <GatsbyImage
                    image={image}
                    alt="CheshTech William Cheshier"
                    placeholder="blurred"
                  />
                  <Box>
                    <Column
                      alignitems="center"
                      justifycontent="center"
                      height="100%"
                      margin="auto 0"
                    >
                      <Text textAlign="center" white>
                        {parse(node.excerpt)}
                      </Text>
                    </Column>
                  </Box>
                </BoxWrapper>
              </Link>
            )
          })}
        </Grid>
        {type === "home" && (
          <Link to="/work">
            <Button margin="30px 0 0 0">Case Studies</Button>
          </Link>
        )}
      </Column>
    </StyledWrapper>
  )
}
