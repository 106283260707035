import React from "react"
import styled from "styled-components"
import { Row, Text, Column, Subtitle } from "../theme/index"
import { Responsive, Colors } from "../theme/styles"
import { FaFacebook, FaInstagram, FaLinkedin } from "react-icons/fa"
import { Mailchimp } from "./mailchimp"

const SubscribeBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin: 20px auto 0 auto;
  background: ${Colors.lightBlue};
  padding: 16px;
`

const OutlineBox = styled(Column)`
  ${Responsive.sm`
    margin-top: 30px;
    width: ${props => props.width || "85%"};
    align-items: flex-start;
    border: 1px dashed ${Colors.lightBlue};

  `}
  ${Responsive.md`
  margin-top: 30px;
  width: ${props => props.width || "65%"};
`}
  ${Responsive.lg`
  margin-top: 0px;
  width: ${props => props.width || "45%"};
`}
`

const SocialMedia = styled.div`
  width: 100%;
  border: 2px solid ${Colors.lightBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`

const ContactUs = styled.div`
  width: 100%;
  border: 2px solid ${Colors.lightBlue};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: ${props => (props.margintop ? "30px" : "15px")};
`

const StyledLink = styled.a`
  ${Responsive.sm`
cursor: pointer;
color: ${Colors.darkBlue};
margin: ${props => (props.margin ? "0px 15px 0 0" : "0")};
&:hover {
  color: ${Colors.lightBlue};
}
`}
  ${Responsive.lg`
margin-top: 0px;
`}
`
export const Sidebar = ({ type, margintop, width, node, WpImage, url }) => {
  return (
    <OutlineBox type={type} width={width}>
      <Column margin="15px auto" width="90%">
        <SubscribeBox>
          <Column width="100%">
            <Subtitle small white textAlign="center">
              Download our 5 website elements to boost conversions guide
            </Subtitle>
            <Text white textAlign="center">
              Wondering how to generate more leads through your website?
              Download our guide to help you structure your website in a way
              that converts your visitors into leads!
            </Text>
            <Mailchimp />
          </Column>
        </SubscribeBox>
        <ContactUs margintop={margintop}>
          <Subtitle black>Contact Us</Subtitle>
          <Row alignitems="center">
            <faPhone />
            <Text margin="0 0 0 10px">(206) 568-8628</Text>
          </Row>
          <Row alignitems="center">
            <faEnvelope />
            <Text margin="0 0 0 10px">info@cheshtech.com</Text>
          </Row>
        </ContactUs>
        <SocialMedia>
          <Column margin="20px auto">
            <Text black>Follow us on social media</Text>
            <Row justifycontent="center" alignitems="center">
              <StyledLink margin href="https://facebook.com/CheshTech">
                <FaFacebook size={40} />
              </StyledLink>
              <StyledLink margin href="https://instagram.com/CheshTech">
                <FaInstagram size={40} />
              </StyledLink>
              <StyledLink href="https://www.linkedin.com/company/cheshtech/">
                <FaLinkedin size={40} />
              </StyledLink>
            </Row>
          </Column>
        </SocialMedia>
      </Column>
    </OutlineBox>
  )
}
