import React, { Fragment } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { Text, Column, Button } from "../theme/index"
import { GatsbyImage } from "gatsby-plugin-image"
import { Colors } from "../theme/styles"
import parse from "html-react-parser"

const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 100%;
  margin: 0 auto;
  background: ${props => props.background};
  border: 1.5px solid ${Colors.lightBlue};
  transition: 250ms;
  border-radius: 8px;
  @media (min-width: 920px) {
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 24px 0 rgba(138, 145, 156, 0.2);
    }
  }
`

export const BlogCard = ({ type, node, content, WpImage, url, blogUrl }) => {
  return (
    <Fragment>
      {type === "blogPage" && (
        <Link to={blogUrl} style={{ textDecoration: "none" }}>
          <BlogWrapper>
            <Column margin="0px 0 10px 0">
              <GatsbyImage
                image={WpImage}
                alt="CheshTech William Cheshier"
                placeholder="blurred"
              />
              <Text
                fontweight="bold"
                textAlign="center"
                black
                margin="10px 0 0 0"
              >
                {node.title}
              </Text>
              <Link to={url}>
                <Text black small textAlign="center">
                  {node.date}
                </Text>
                <Text black small width="90%" margin="0 auto">
                  {parse(node.excerpt)}
                </Text>
              </Link>
              <Button hover={Colors.yellow} margin="10px 0 0 0">
                Read more
              </Button>
            </Column>
          </BlogWrapper>
        </Link>
      )}
      {type !== "blogPage" && (
        <Column margin="15px 0 0 0">
          <Link to={url}>
            <BlogWrapper>
              <Column margin="0px 0 10px 0">
                <GatsbyImage
                  image={WpImage}
                  alt="CheshTech William Cheshier"
                  placeholder="blurred"
                />
                <Text
                  fontweight="bold"
                  textAlign="center"
                  black
                  margin="10px 0 0 0"
                >
                  {content.title}
                </Text>
                <Text black small width="90%" margin="0 auto">
                  {parse(content.excerpt)}
                </Text>
                <Button hover={Colors.yellow} margin="10px 0 0 0">
                  Read more
                </Button>
              </Column>
            </BlogWrapper>
          </Link>
        </Column>
      )}
    </Fragment>
  )
}

// <Fragment>
// {type === 'blogPage' && (

// )}
// <Link to={blogUrl} style={{ textDecoration: "none" }}>
// <BlogWrapper>
//   <Column margin="0px 0 10px 0">
//     {type === "blogPage" && (
//       <GatsbyImage
//         image={WpImage}
//         alt="CheshTech William Cheshier"
//         placeholder="blurred"
//       />
//     )}
//     {type !== "blogPage" && (
//       <GatsbyImage
//         image={WpImage}
//         alt="CheshTech William Cheshier"
//         placeholder="blurred"
//       />
//     )}
//     {type === "blogPage" && (
//       <Text textAlign="center" black margin="10px 0 0 0">
//         {node.title}
//       </Text>
//     )}
//     {type !== "blogPage" && (
//       <Text textAlign="center" black margin="10px 0 0 0">
//         {content.title}
//       </Text>
//     )}
//     {type === "blogPage" && (
//       <Link to={url}>
//         <Text black small textAlign="center">
//           {node.date}
//         </Text>
//         <Text black small width="90%" margin="0 auto">
//           {parse(node.excerpt)}
//         </Text>
//       </Link>
//     )}
//     <Button hover={Colors.yellow} margin="10px 0 0 0">
//       Read more
//     </Button>
//   </Column>
// </BlogWrapper>
// </Link>
// </Fragment>
