import React from "react"
import {
  FaTools,
  FaLaptopCode,
  FaCode,
  FaCodeBranch,
  FaDollarSign,
  FaCloud,
  FaAppStore,
  FaDev,
} from "react-icons/fa"
import { Colors } from "../theme/styles"

export const useAppServices = () => {
  const appData = [
    {
      icon: <FaCode size={60} color={Colors.darkBlue} />,
      text: "Product Development",
    },
    {
      icon: <FaCodeBranch size={60} color={Colors.darkBlue} />,
      text: "Software Development",
    },
    {
      icon: <FaAppStore size={60} color={Colors.darkBlue} />,
      text: "Mobile Application Development",
    },
    {
      icon: <FaDollarSign size={60} color={Colors.darkBlue} />,
      text: "E-Commerce Development",
    },
    {
      icon: <FaDev size={60} color={Colors.darkBlue} />,
      text: "DevOps",
    },
    {
      icon: <FaCloud size={60} color={Colors.darkBlue} />,
      text: "Cloud Strategy",
    },
    {
      icon: <FaTools size={60} color={Colors.darkBlue} />,
      text: "Quality Assurance",
    },
    {
      icon: <FaLaptopCode size={60} color={Colors.darkBlue} />,
      text: "Platform Management",
    },
  ]
  return appData
}
