import React, { useState, Fragment } from "react"
import styled from "styled-components"
import {
  SEOTitle,
  Subtitle,
  Container,
  Text,
  Column,
  Button,
  Wrapper,
} from "../theme/index"
import { Modal } from "./modal"
import { StaticImage } from "gatsby-plugin-image"
import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import {
  MarketingWorkExamples,
  BrandingWorkExamples,
  ProductWorkExamples,
} from "."

const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props =>
    props.marketing
      ? "repeat(auto-fit, 300px)"
      : props.branding
      ? "repeat(auto-fit, 400px)"
      : "repeat(auto-fit, 500px)"};
  grid-gap: ${props =>
    props.marketing ? "45px" : props.branding ? "15px" : "45px"};
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, 300px);
    margin-top: 10px;
  }
`

export const ServiceExamples = ({ service, title }) => {
  const [show, setShow] = useState(false)
  const [type, setType] = useState("")

  const work = useStaticQuery(graphql`
    query {
      iffertmedia: file(relativePath: { eq: "clientwork/iffertmedia.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      llt: file(relativePath: { eq: "clientwork/llt.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      zippota: file(relativePath: { eq: "clientwork/zippotahome.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      parakeet: file(relativePath: { eq: "clientwork/parakeet.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      achievece: file(relativePath: { eq: "clientwork/achievece.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      interior: file(relativePath: { eq: "clientwork/interiormotives.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      nfd: file(relativePath: { eq: "clientwork/nfdwebsite.jpeg" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const images = [
    {
      img: work.iffertmedia.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Iffert Media",
      title: "Iffert Media: Social Media Marketing Agency",
      technology: "Gatsby.js (Static Site Generator)",
      text: "Custom devleoped website for a social media marketing agency out of Nashville, TN. Built using Gatsby as a static site generator providing faster site speed, better CDN delivery, and higher overall performance.",
      btn: "Visit Website",
      link: "https://iffertmedia.com",
      type: "iffertmedia",
      id: "0",
    },
    {
      img: work.llt.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Living Loving Team",
      title: "Living Loving Team: Headless CMS (Gatsby + Contentful CMS)",
      technology: "Headless CMS (Gatsby + Contentful CMS)",
      text: "Build out a headless CMS website with linked search functionality to the IDXBroker subdomain. A high performing website that allows prospective house hunters to search for listings from the Living Loving Team.",
      btn: "Visit Website",
      link: "https://livinglovingteam.com",
      type: "llt",
      id: "1",
    },
    {
      img: work.zippota.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Zippota",
      title: "Zippota: Online Fashion Store for the LatinX Community",
      technology: "Shopify",
      text: "Shopify online store designed to be simple, elegant, easy to use, and a user experience that's designed to convert website visitors. An online fashion shop for the LatinX community.",
      btn: "Visit Website",
      link: "https://zippota.com",
      type: "zippota",
      id: "2",
    },
    {
      img: work.nfd.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: NightFoxDude",
      title: "NightFoxDude: Twitch Streamer and Internet Personality",
      technology: "Headless CMS (Gatsby + WordPress)",
      text: "An optimized headless CMS website built with Gatsby and WordPress and deployed by Netlify. A website that performs highly across all website metrics giving this LatinX Twitch Streamer a leg up against his competition.",
      btn: "Visit Website",
      link: "https://nightfoxdude.com",
      type: "nfd",
      id: "3",
    },
    {
      img: work.interior.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: InteriorMotives",
      title: "Interior Motives Flooring: Flooring and Installation Company",
      technology: "WordPress (WPBakery Builder)",
      text: "Built using the WPBakery page builder, this website performs at a high level on the WordPress platform utilizing the proper plugins for performance.",
      btn: "Visit Website",
      link: "https://interiormotivesflooring.com",
      type: "interior",
      id: "4",
    },
  ]

  const toggleModal = key => {
    setShow(!show)
    if (key.type === "iffertmedia") {
      setType("iffertmedia")
    } else if (key.type === "llt") {
      setType("llt")
    } else if (key.type === "zippota") {
      setType("zippota")
    } else if (key.type === "interior") {
      setType("interior")
    } else if (key.type === "interior") {
      setType("interior")
    } else {
      setType("nfd")
    }
  }
  return (
    <Wrapper lightBlue>
      <Container>
        <Column>
          <SEOTitle white>{title}</SEOTitle>
          <Grid>
            {service === "website" &&
              images.map((key, index) => {
                const image = getImage(key.img)
                return (
                  <Fragment key={index}>
                    <GatsbyImage
                      image={image}
                      alt="CheshTech Raquel Cespedes Diaz"
                      placeholder="blurred"
                      style={{
                        marginBottom: "10px",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleModal(key)}
                    />
                  </Fragment>
                )
              })}

            {type === "llt" && (
              <Modal show={show} toggleModal={toggleModal}>
                <Column width="90%" margin="0 auto 15px auto">
                  <StaticImage
                    src="../images/clientwork/llt.jpeg"
                    alt="CheshTech Real Estate Marketing and Websites"
                    placeholder="blurred"
                    style={{ marginBottom: "10px", borderRadius: "10px" }}
                  />
                  <Subtitle small>
                    Living Loving Team: Real Estate Team
                  </Subtitle>
                  <Subtitle small>Headless CMS</Subtitle>
                  <Subtitle small>Requirements:</Subtitle>
                  <Text>
                    Build out a headless CMS website with linked search
                    functionality to the IDXBroker subdomain. A high performing
                    website that allows prospective house hunters to search for
                    listings from the Living Loving Team.
                  </Text>
                  <a href="https://livinglovingteam.com" target="__blank">
                    <Button margin="15px 0 0 0">Visit Website</Button>
                  </a>
                </Column>
              </Modal>
            )}
            {type === "zippota" && (
              <Modal show={show} toggleModal={toggleModal}>
                <Column width="90%" margin="0 auto 15px auto">
                  <StaticImage
                    src="../images/clientwork/zippotahome.png"
                    alt="CheshTech Digital Agency: Modern websites, apps, and digital marketing"
                    placeholder="blurred"
                    style={{ marginBottom: "10px", borderRadius: "10px" }}
                  />
                  <Subtitle small>
                    Zippota: Online Fashion Store for the LatinX Community
                  </Subtitle>
                  <Subtitle small>Shopify</Subtitle>
                  <Subtitle small>Requirements:</Subtitle>
                  <Text>
                    Shopify online store designed to be simple, elegant, easy to
                    use, and a user experience that's designed to convert
                    website visitors. An online fashion shop for the LatinX
                    community.
                  </Text>
                  <a href="https://zippota.com" target="__blank">
                    <Button margin="15px 0 0 0">Visit Website</Button>
                  </a>
                </Column>
              </Modal>
            )}
            {type === "iffertmedia" && (
              <Modal show={show} toggleModal={toggleModal}>
                <Column width="90%" margin="0 auto 15px auto">
                  <StaticImage
                    src="../images/clientwork/iffertmedia.jpeg"
                    alt="CheshTech Headless CMS Website Development"
                    placeholder="blurred"
                    style={{ marginBottom: "10px", borderRadius: "10px" }}
                  />
                  <Subtitle small>
                    Iffert Media: Social Media Marketing Agency
                  </Subtitle>
                  <Subtitle small>Gatsby.js (Static Site Generator)</Subtitle>
                  <Subtitle small>Requirements:</Subtitle>
                  <Text>
                    Custom devleoped website for a social media marketing agency
                    out of Nashville, TN. Built using Gatsby as a static site
                    generator providing faster site speed, better CDN delivery,
                    and higher overall performance.
                  </Text>
                  <a href="https://iffertmedia.com" target="__blank">
                    <Button margin="15px 0 0 0">Visit Website</Button>
                  </a>
                </Column>
              </Modal>
            )}
            {type === "nfd" && (
              <Modal show={show} toggleModal={toggleModal}>
                <Column width="90%" margin="0 auto 15px auto">
                  <StaticImage
                    src="../images/clientwork/nfdwebsite.jpeg"
                    alt="CheshTech Headless CMS Website Development"
                    placeholder="blurred"
                    style={{ marginBottom: "10px", borderRadius: "10px" }}
                  />
                  <Subtitle small>
                    NightFoxDude: Twitch Streamer and Internet Personality
                  </Subtitle>
                  <Subtitle small>Headless CMS (Gatsby + WordPress)</Subtitle>
                  <Subtitle small>Requirements:</Subtitle>
                  <Text>
                    An optimized headless CMS website built with Gatsby and
                    WordPress and deployed by Netlify. A website that performs
                    highly across all website metrics giving this LatinX Twitch
                    Streamer a leg up against his competition.
                  </Text>
                  <a href="https://nightfoxdude.com" target="__blank">
                    <Button margin="15px 0 0 0">Visit Website</Button>
                  </a>
                </Column>
              </Modal>
            )}
            {type === "interior" && (
              <Modal show={show} toggleModal={toggleModal}>
                <Column width="90%" margin="0 auto 15px auto">
                  <StaticImage
                    src="../images/clientwork/interiormotives.png"
                    alt="CheshTech WordPress Website Development"
                    placeholder="blurred"
                    style={{ marginBottom: "10px", borderRadius: "10px" }}
                  />
                  <Subtitle small>
                    Interior Motives Flooring: Flooring and Installation Company
                  </Subtitle>
                  <Subtitle small>WordPress (WPBakery Builder)</Subtitle>
                  <Subtitle small>Requirements:</Subtitle>
                  <Text>
                    Built using the WPBakery page builder, this website performs
                    at a high level on the WordPress platform utilizing the
                    proper plugins for performance.
                  </Text>
                  <a
                    href="https://interiormotivesflooring.com"
                    target="__blank"
                  >
                    <Button margin="15px 0 0 0">Visit Website</Button>
                  </a>
                </Column>
              </Modal>
            )}
            {service === "apps" && <ProductWorkExamples />}
          </Grid>
          <Grid marketing>
            {service === "marketing" && <MarketingWorkExamples />}
          </Grid>
          <Grid branding>
            {service === "branding" && <BrandingWorkExamples />}
          </Grid>
        </Column>
      </Container>
    </Wrapper>
  )
}
