import React, { useState, useEffect } from "react"
import styled from "styled-components"
import NavLogo from "../images/CheshTechLogoIconblue.png"
import { Link } from "gatsby"
import { Tooltip } from "./tooltip"
import { Row, Nav, Column } from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import { IoMdArrowDropdown } from "react-icons/io"

const Wrapper = styled.div`
  ${Responsive.sm`
  background: ${props => props.background};
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100000;
  padding: 60px 0;
  transition: 250ms;
  `}
  ${Responsive.lg`
  background: ${props => props.background};
  width: 100%;
  height: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 100000;
  padding: 0;
  transition: 250ms;
  `}
`

const Logo = styled.img`
  width: 75px;
  margin-bottom: 0px;
  border-radius: 50%;
  @media (max-width: 720px) {
    width: 50px;
  }
`

const StyledRow = styled(Row)`
  width: 90%;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: center;
  }
`

const NavRow = styled(Row)`
  align-items: center;
  margin: 0;
  @media (max-width: 920px) {
    margin-top: 10px;
  }
`

const NavLinks = styled(Nav)`
  color: ${props => props.color};
  curosr: pointer;
`

const DropdownLinks = styled(Nav)`
  text-align: center;
  color: ${Colors.darkBlue};
  cursor: pointer;
  width: 100%;
  &:after {
    content: "";
    display: block;
    margin-top: 5px;
    margin-left: auto;
    margin-right: auto;
    height: 2px;
    width: 0px;
    background-color: ${Colors.lightBlue};
  }
  &:hover:after {
    width: 50%;
    transition: all 0.4s;
  }
`

export const Navigation = () => {
  const [color, setNavColor] = useState("rgba(0,0,0,0)")
  const [linkColor, setLinkColor] = useState(`${Colors.white}`)
  const [show, setShow] = useState(false)

  const listenScrollEvent = e => {
    if (window.scrollY > 75) {
      setNavColor(`${Colors.white}`)
      setLinkColor(`${Colors.darkBlue}`)
    } else {
      setNavColor("rgba(0,0,0,0)")
      setLinkColor(`${Colors.white}`)
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent)

    return () => {
      window.removeEventListener("scroll", listenScrollEvent)
    }
  }, [])

  const handleClick = () => {
    setShow(!show)
  }

  return (
    <Wrapper background={color}>
      <StyledRow>
        <Row alignitems="center">
          <Link to="/">
            <Logo
              src={NavLogo}
              alt="CheshTech Digital Agency: Modern websites, apps, and digital marketing"
            />
          </Link>
        </Row>
        <NavRow>
          <NavLinks to="/" color={linkColor}>
            Home
          </NavLinks>
          <NavLinks to="/about" color={linkColor}>
            About
          </NavLinks>
          <Tooltip
            show={show}
            type="menu"
            links={
              <Column width="100%" margin="0 auto">
                <DropdownLinks to="/services" left>
                  All Services
                </DropdownLinks>
                <DropdownLinks to="/services/website-design-development" left>
                  Web Design and Development
                </DropdownLinks>

                <DropdownLinks
                  to="/services/web-mobile-application-development"
                  left
                >
                  Application Development
                </DropdownLinks>
                <DropdownLinks to="/services/marketing-services" left>
                  Digital Marketing
                </DropdownLinks>
              </Column>
            }
          >
            <Row alignitems="center" style={{ cursor: "pointer" }}>
              <NavLinks onClick={() => handleClick()} color={linkColor}>
                Services
              </NavLinks>
              <IoMdArrowDropdown
                size={20}
                color={linkColor}
                style={{ cursor: "pointer" }}
              />
            </Row>
          </Tooltip>
          <Tooltip
            show={show}
            type="menu"
            links={
              <Column width="100%" margin="0 auto">
                <DropdownLinks to="/blog" left>
                  Blog
                </DropdownLinks>
                <DropdownLinks to="/talkin-solutions-podcast" left>
                  Podcast
                </DropdownLinks>
                <DropdownLinks to="/resources" left>
                  Links + Free Content
                </DropdownLinks>
              </Column>
            }
          >
            <Row alignitems="center" style={{ cursor: "pointer" }}>
              <NavLinks color={linkColor}>Resources</NavLinks>
              <IoMdArrowDropdown
                size={20}
                color={linkColor}
                style={{ cursor: "pointer" }}
              />
            </Row>
          </Tooltip>
          <NavLinks to="/work" color={linkColor}>
            Portfolio
          </NavLinks>
          <NavLinks to="/contact" color={linkColor}>
            Contact
          </NavLinks>
        </NavRow>
      </StyledRow>
    </Wrapper>
  )
}
