import React from "react"
import styled from "styled-components"
import { Wrapper, Container, Row, Text, Subtitle, Column } from "../theme/index"
import { Mailchimp } from "./mailchimp"
import { Colors, Responsive } from "../theme/styles"
import { FaFacebook, FaInstagram, FaLinkedin, FaTwitter } from "react-icons/fa"

const FooterRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: column;
    align-items: center;
    justify-content: center;
`}
  ${Responsive.lg`
flex-direction: row;
justify-content: space-between;
align-items: flex-start;
`}
`

const Divider = styled.div`
  border-bottom: 2px solid #fff;
  width: 50%;
  margin: 0 auto;
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
        margin-top: 30px;
        align-items: center;
        width: 100%;
    `}
  ${Responsive.lg`
    margin-top: 0px;
    align-items: center;
    width: 100%;
`}
`

const StyledLink = styled.a`
  ${Responsive.sm`
cursor: pointer;
margin: ${props => (props.margin ? "0px 15px 0 0" : "0")};
&:hover {
  color: ${Colors.darkBlue};
}
`}
  ${Responsive.lg`
margin-top: 0px;
`}
`

export const Footer = () => {
  return (
    <Wrapper style={{ backgroundColor: `${Colors.lightBlue}` }}>
      <Container>
        <FooterRow>
          <Column alignitems="center" width="100%">
            <Subtitle white>Follow us on Social Media</Subtitle>
            <Row justifycontent="center" alignitems="center">
              <StyledLink margin href="https://facebook.com/CheshTech">
                <FaFacebook color={Colors.white} size={40} />
              </StyledLink>
              <StyledLink margin href="https://instagram.com/CheshTech">
                <FaInstagram color={Colors.white} size={40} />
              </StyledLink>
              <StyledLink margin href="https://twitter.com/CheshTech_">
                <FaTwitter color={Colors.white} size={40} />
              </StyledLink>
              <StyledLink href="https://www.linkedin.com/company/cheshtech/">
                <FaLinkedin color={Colors.white} size={40} />
              </StyledLink>
            </Row>
          </Column>
          <StyledColumn>
            <Mailchimp type="footer" />
          </StyledColumn>
          <StyledColumn>
            <Subtitle white>Contact Us</Subtitle>
            <Text white>Email: info@cheshtech.com</Text>
            <Text white>Phone: (206) 569-8628</Text>
          </StyledColumn>
        </FooterRow>
        <Column margin="50px 0 0 0" alignitems="center">
          <Divider />
          <Text margin="10px 0 0 0" white>
            © 2021 - CheshTech - All Rights Reserved
          </Text>
        </Column>
      </Container>
    </Wrapper>
  )
}
