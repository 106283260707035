import React from "react"
import styled from "styled-components"
import { Wrapper, Container, Text, Column } from "../theme/index"
import { Colors, Fonts, Responsive } from "../theme/styles"
import { FaLaptopCode, FaHeart } from "react-icons/fa"
import { GiEarthAmerica } from "react-icons/gi"

const ServiceCard = styled.div`
  ${Responsive.sm`
display: flex;
justify-content: center;
background: ${Colors.white};
align-items: center;
border: 2px solid ${Colors.darkBlue};
width: 100%;
color: ${Colors.black};
margin-top: 30px;
padding: 20px;
margin-bottom: 30px;
font-family: ${Fonts.montserrat};
margin-right: ${props => (props.marginRight ? "10px" : "0")};
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
transition: 0.3s;
&:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}
`}
  ${Responsive.lg`
margin-top: 0px;
margin-bottom: 0px;
`}
`

const ServicesGrid = styled.div`
  width: 100%;
  display: grid;
  grid-gap: 15px;
  margin-top: -100px;
  z-index: 10000;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  @media (max-width: 769px) {
    display: block;
    // flex-direction: column;
  }
`

const StyledText = styled(Text)`
  color: inherit;
  margin-top: 10px;
`

const data = [
  {
    icon: <FaLaptopCode size={60} color={Colors.darkBlue} />,
    service: "Growth Catalysts",
    // list1: "Direct access to our leadership team of experts",
    margin: true,
  },
  {
    icon: <GiEarthAmerica size={60} color={Colors.darkBlue} />,
    service: "Impact Driven",
    // list1: "Conversion centric websites built for fulfilling your goals",
    margin: true,
  },
  {
    icon: <FaHeart size={60} color={Colors.darkBlue} />,
    service: "Empowering Changemakers",
    // list1: "Social media management and scheduling",
    margin: true,
  },
]

export const ValueProps = () => {
  return (
    <Wrapper>
      <Container>
        <Column alignitems="center">
          <ServicesGrid>
            {data.map((key, index) => {
              return (
                <ServiceCard marginRight key={index}>
                  <Column
                    alignitems="center"
                    justifycontent="center"
                    width="90%"
                    margin="0px auto"
                  >
                    {key.icon}
                    <Column margin="0 0 0 10px">
                      <StyledText textAlign="center">{key.service}</StyledText>
                      <StyledText textAlign="center">{key.list1}</StyledText>
                    </Column>
                  </Column>
                </ServiceCard>
              )
            })}
          </ServicesGrid>
        </Column>
      </Container>
    </Wrapper>
  )
}
