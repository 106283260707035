import React, { Fragment } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  SEOTitle,
  Subtitle,
  Container,
  Row,
  Text,
  Column,
  Wrapper,
  Button,
} from "../theme/index"
import {
  useMarketingServices,
  useWebDevServices,
  useBrandingServices,
  useAppServices,
} from "../constants/index"
import { Responsive, Colors } from "../theme/styles"
import {
  DiJavascript1,
  DiMongodb,
  DiMysql,
  DiReact,
  DiNodejs,
} from "react-icons/di"
import { GrGatsbyjs } from "react-icons/gr"
import {
  FaAws,
  FaWordpress,
  FaGithub,
  FaShopify,
  FaHtml5,
  FaCss3,
} from "react-icons/fa"
import { SiNextDotJs, SiNetlify } from "react-icons/si"

const StyledRow = styled(Row)`
  ${Responsive.sm`
    flex-direction: column-reverse;
    align-items: center;
    justify-content: center;
  `}
  ${Responsive.md`
    justify-content: space-between;
    flex-direction: row;
    align-items: flex-start;
  `}
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
    align-items: center;
    width: 100%; 
    margin: ${props =>
      props.margin ? "0 20px 0 0" : props.top ? "50px 0 0 0" : "0"};
  `}
  ${Responsive.lg`
    width: ${props => props.width || "50%"}; 
    margin: ${props =>
      props.margin ? "10px 0px 0 0" : props.top ? "0px 0 0 0" : "0"};
    
`}
`

const StyledSubtitle = styled(Subtitle)`
  margin: 40px auto;
  text-align: left;
`

const Divider = styled.div`
  border-bottom: 2px solid #fff;
  width: 50%;
  margin: 20px auto;
`
const Grid = styled.div`
  display: grid;
  grid-template-columns: ${props => props.columns || "repeat(auto-fit, 175px)"};
  gap: 25px;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, 100px);
  }
`

const ServiceWrapper = styled(Wrapper)`
  background: ${Colors.lightBlue};
`

const StyledText = styled(Text)`
  margin: ${props => (props.margin ? "20px 0" : "0")};
  text-align: left;
  color: ${Colors.white};
`

const StyledButton = styled(Button)`
  ${Responsive.sm`
  width: 100%;
  margin: 40px 0;
  background: ${Colors.darkBlue};
  `}
  ${Responsive.lg`
  margin: 15px 0 0 0;
  `}
`

const technologyData = [
  {
    icon: <FaWordpress size={60} color={Colors.darkBlue} />,
    text: "WordPress",
  },
  {
    icon: <FaShopify size={60} color={Colors.darkBlue} />,
    text: "Shopify",
  },
  {
    icon: <FaGithub size={60} color={Colors.darkBlue} />,
    text: "Github",
  },
  {
    icon: <SiNetlify size={60} color={Colors.darkBlue} />,
    text: "Netlify",
  },
  {
    icon: <GrGatsbyjs size={60} color={Colors.darkBlue} />,
    text: "Gatsby",
  },
  {
    icon: <SiNextDotJs size={60} color={Colors.darkBlue} />,
    text: "Next.js",
  },
  {
    icon: <FaHtml5 size={60} color={Colors.darkBlue} />,
    text: "HTML5",
  },
  {
    icon: <FaCss3 size={60} color={Colors.darkBlue} />,
    text: "CSS",
  },
  {
    icon: <DiReact size={60} color={Colors.darkBlue} />,
    text: "React",
  },
  {
    icon: <DiNodejs size={60} color={Colors.darkBlue} />,
    text: "Node.js",
  },
]

const appTechnology = [
  {
    icon: <DiReact size={60} color={Colors.darkBlue} />,
    text: "React",
  },
  {
    icon: <DiJavascript1 size={60} color={Colors.darkBlue} />,
    text: "JavaScript",
  },
  {
    icon: <DiNodejs size={60} color={Colors.darkBlue} />,
    text: "Node.js",
  },
  {
    icon: <DiMongodb size={60} color={Colors.darkBlue} />,
    text: "MongoDB",
  },
  {
    icon: <DiMysql size={60} color={Colors.darkBlue} />,
    text: "MySQL",
  },
  {
    icon: <GrGatsbyjs size={60} color={Colors.darkBlue} />,
    text: "Gatsby",
  },
  {
    icon: <FaAws size={60} color={Colors.darkBlue} />,
    text: "AWS",
  },
  {
    icon: <SiNextDotJs size={60} color={Colors.darkBlue} />,
    text: "Next.js",
  },
]

export const ServicesRow = ({
  type,
  serviceTitle,
  category,
  service,
  subtitle,
  paragraph1,
  paragraph2,
  paragraph3,
}) => {
  const marketingData = useMarketingServices()
  const webDevData = useWebDevServices()
  const brandingData = useBrandingServices()
  const appData = useAppServices()

  return (
    <ServiceWrapper>
      <Container>
        <StyledRow>
          <StyledColumn>
            <Subtitle white>{category}</Subtitle>
            <Grid>
              {service === "webdev" &&
                webDevData.map((key, index) => {
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Text textAlign="center" white>
                        {key.text}
                      </Text>
                    </Column>
                  )
                })}
              {service === "marketing" &&
                marketingData.map((key, index) => {
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Text textAlign="center" white>
                        {key.text}
                      </Text>
                    </Column>
                  )
                })}
              {service === "branding" &&
                brandingData.map((key, index) => {
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Text textAlign="center" white>
                        {key.text}
                      </Text>
                    </Column>
                  )
                })}
              {service === "apps" &&
                appData.map((key, index) => {
                  console.log(key.icon, "ANAKIN")
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Text textAlign="center" white>
                        {key.text}
                      </Text>
                    </Column>
                  )
                })}
            </Grid>
            {service === "webdev" && (
              <Fragment>
                <Divider />
                <Subtitle white>Our Technologies</Subtitle>
                <Grid columns="repeat(auto-fit, 100px)">
                  {technologyData.map((key, index) => {
                    return (
                      <Column key={index}>
                        {key.icon}
                        <Text white>{key.text}</Text>
                      </Column>
                    )
                  })}
                </Grid>
              </Fragment>
            )}
            {service === "apps" && (
              <Fragment>
                <Divider />
                <Subtitle white>Our Technologies</Subtitle>
                <Grid columns="repeat(auto-fit, 100px)">
                  {appTechnology.map((key, index) => {
                    return (
                      <Column key={index}>
                        {key.icon}
                        <Text white>{key.text}</Text>
                      </Column>
                    )
                  })}
                </Grid>
              </Fragment>
            )}
          </StyledColumn>
          <StyledColumn width="45%">
            <SEOTitle white margin="0">
              {serviceTitle}
            </SEOTitle>
            <StyledSubtitle white>{subtitle}</StyledSubtitle>
            <StyledText>{paragraph1}</StyledText>
            <StyledText margin>{paragraph2}</StyledText>
            <StyledText>{paragraph3}</StyledText>
            <Link to="/schedule-consultation">
              <StyledButton hover={Colors.black}>
                Book a Free Consultation
              </StyledButton>
            </Link>
          </StyledColumn>
        </StyledRow>
      </Container>
    </ServiceWrapper>
  )
}
