import React from "react"
import styled from "styled-components"
import { Row, Button } from "../theme/index"
import { Responsive } from "../theme/styles"
import { Link } from "gatsby"

const Container = styled(Row)`
  ${Responsive.sm`
justify-content: ${props => (props.justifycontent ? "center" : "center")};
align-items: center;
flex-direction: column;
`}
  ${Responsive.lg`
flex-direction: row;
justify-content: ${props => (props.justifycontent ? "flex-start" : "center")};
align-items: center;
`}
`

const StyledButton = styled(Button)`
  ${Responsive.sm`
margin-right: 0px;
margin-bottom: 10px;
`}
  ${Responsive.lg`
margin-right: 10px;
margin-bottom: 0px;
`}
`

export const ButtonContainer = ({ link, link2, btnText, btnText2, type }) => {
  return (
    <Container>
      <Link to={link}>
        <StyledButton>{btnText}</StyledButton>
      </Link>
      {type === "podcast" && (
        <a href="https://linktr.ee/talkinsolutionspodcast" target="__blank">
          <Button>{btnText2}</Button>
        </a>
      )}
      {type !== "podcast" && (
        <Link to={link2}>
          <Button>{btnText2}</Button>
        </Link>
      )}
    </Container>
  )
}
