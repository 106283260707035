import React, { Fragment } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { SEOTitle, Container, Button, Column, Text, Row } from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import NavLogo from "../images/CheshTechLogoIconblue.png"
import { Mailchimp } from "../components/index"
import { StaticImage } from "gatsby-plugin-image"

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  align-items: center;
  justify-content: center;
  //   background-color: #d3e9d6;
  // background-color: ${Colors.black};
  background-color: ${Colors.darkBlue};
  padding: 25px 0;
  @media (max-width: 1200px) {
    padding: 2em;
    margin: 0;
    flex-direction: column;
  }
`

const FooterWrapper = styled.div`
  width: 100%;
  height: 100%;
  margin-top: 30px;
  //   background-color: #d3e9d6;
  // background-color: ${Colors.black};
  background-color: ${Colors.darkBlue};
  padding: 25px 0;
  @media (max-width: 1200px) {
    padding: 2em;
    margin: 0;
  }
`

const RightButton = styled(Button)`
  padding: 10px;
  color: ${Colors.white};
  border-radius: 3px;
  background: ${Colors.black};
  &:hover {
    background: ${Colors.lightBlue};
    color: ${Colors.white};
  }
`

const LeftButton = styled(Button)`
  ${Responsive.sm`
margin-right: 0px;
margin-bottom: 10px;
padding: 10px;
color: ${Colors.white};
border-radius: 3px;
background: ${Colors.black};
`}
  ${Responsive.lg`
margin-right: 10px;
margin-bottom: 0px;
`}
&:hover {
    background: ${Colors.lightBlue};
    color: ${Colors.white};
  }
`

const Logo = styled.img`
  width: 75px;
  margin-bottom: 0px;
  border-radius: 50%;
  @media (max-width: 720px) {
    width: 50px;
  }
`

const Grid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 300px);
gap: 15px;
width: 100%;
margin-top: 30px;
padding-bottom: 30px;
justify-content: center;
`}
  ${Responsive.md`
grid-template-columns: repeat(auto-fit, 350px);
`}
  ${Responsive.lg`
display: grid;
grid-template-columns: repeat(auto-fit, 450px);
gap: 45px;
width: 100%;
margin-top: 30px;
justify-content: center;
`}
`

const StyledLink = styled(Link)`
  color: #fff;
  margin-right: ${props => (props.marginright ? "0px" : "10px")};
`

const StyledColumn = styled(Column)`
  ${Responsive.sm`
flex-direction: column;
margin: 25px 0 25px 0;
width: 100%
`}
  ${Responsive.lg`
flex-direction: row;
justify-content: space-between;
margin: 75px auto 75px auto;
width: 90%
`}
`
const ButtonContainer = styled(Row)`
  ${Responsive.sm`
justify-content: center;
align-items: center;
flex-direction: column;
`}
  ${Responsive.lg`
flex-direction: row;
justify-content: center;
align-items: center;
`}
`

const TextColumn = styled(Column)`
  ${Responsive.sm`
width: 100%
align-items: center;
margin-bottom: 30px;
flex-direction: column;
`}
  ${Responsive.lg`
align-items: flex-start;
margin-bottom: 0px;
`}
`

export const CallToAction = ({ ctaType, ctaTitle, type }) => {
  return (
    <Fragment>
      {type !== "landingpage" && (
        <Wrapper>
          <Container>
            <StyledColumn>
              <TextColumn>
                {ctaType !== "custom" && (
                  <Fragment>
                    <SEOTitle textAlign="center" white margin="0">
                      Let's Get Your Project Started
                    </SEOTitle>
                    <Column
                      margin="20px 0 20px 0"
                      width="75%"
                      alignitems="flex-start"
                    >
                      <Text white>
                        Schedule a 30 minute video consultation and we'll
                        discuss your problem, recommend solutions, and most
                        importantly get to know your business. You'll speak
                        directly with CheshTech CEO William Cheshier.
                      </Text>
                      <Text white>
                        Don't feel like meeting face to face to start? Fill out
                        our contact form and our team will get back to you ASAP.
                      </Text>
                    </Column>
                  </Fragment>
                )}
                {ctaType === "custom" && (
                  <SEOTitle textAlign="center" white margin="0">
                    {ctaTitle}
                  </SEOTitle>
                )}
                <ButtonContainer>
                  <Link to="/schedule-consultation">
                    <LeftButton>Book Consultation</LeftButton>
                  </Link>
                  <Link to="/contact">
                    <RightButton>Contact Form</RightButton>
                  </Link>
                </ButtonContainer>
              </TextColumn>
              <Column>
                <StaticImage
                  src="../images/ctawill.jpg"
                  alt="CheshTech William Cheshier"
                  placeholder="blurred"
                  //   layout="fixed"
                  width={1000}
                  height={700}
                  style={{ borderRadius: "5px" }}
                />
                <Text margin="10px 0 0 0" white textAlign="center">
                  CheshTech CEO: William Cheshier
                </Text>
              </Column>
            </StyledColumn>
          </Container>
        </Wrapper>
      )}
      {type === "landingpage" && (
        <FooterWrapper>
          <Grid>
            <Column>
              <SEOTitle white>
                Get your business online and organized. Plus receive more FREE
                tips and tricks to grow your business.
              </SEOTitle>
            </Column>
            <Mailchimp type="landingpage" />
          </Grid>
          <Row
            justifycontent="space-between"
            width="90%"
            margin="0 auto"
            alignitems="center"
          >
            <Row>
              <StyledLink to="/">Home</StyledLink>
              <StyledLink to="/about">About</StyledLink>
              <StyledLink to="/services">Services</StyledLink>
              <StyledLink marginright to="/contact">
                Contact
              </StyledLink>
            </Row>
            <Row alignitems="flex-end">
              <Link to="/">
                <Logo
                  src={NavLogo}
                  alt="CheshTech Digital Agency: We develop websites and create content strategies to bring you leads"
                />
              </Link>
            </Row>
          </Row>
        </FooterWrapper>
      )}
    </Fragment>
  )
}
