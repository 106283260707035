import React from "react"
import styled from "styled-components"
import {
  SEOTitle,
  Text,
  Row,
  Column,
  Wrapper,
  Container,
  Button,
} from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import parse from "html-react-parser"
import { graphql, Link, StaticQuery } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

const Grid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 85%);
gap: 25px;
width: 100%;
justify-content: center;
`}
  ${Responsive.md`

grid-template-columns: repeat(auto-fit, 275px);
`}
  ${Responsive.lg`

grid-template-columns: repeat(auto-fit, 295px);
`}
${Responsive.xlg`

grid-template-columns: repeat(auto-fit, 370px);
`}
`

const BlogWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  cursor: pointer;
  height: 100%;
  margin: 0 auto;
  background: ${Colors.darkBlue};
  border: 1.5px solid ${Colors.lightBlue};
  transition: 250ms;
  border-radius: 8px;
  @media (min-width: 920px) {
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 8px 24px 0 rgba(138, 145, 156, 0.2);
    }
  }
`

const BlogLayout = styled(Row)`
  ${Responsive.sm`
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin: 30px 0 0 0;
  `}
  ${Responsive.lg`
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  `}
`

export const BlogGrid = ({ data }) => (
  <StaticQuery
    query={graphql`
      query {
        allWpPost(
          filter: {
            categories: { nodes: { elemMatch: { name: { eq: "blog" } } } }
          }
          limit: 3
          sort: { order: DESC, fields: [date] }
        ) {
          nodes {
            excerpt
            title
            uri
            slug
            date(formatString: "MM/DD/Y")
            featuredImage {
              node {
                id
                localFile {
                  childImageSharp {
                    gatsbyImageData(
                      width: 800
                      height: 500
                      placeholder: TRACED_SVG
                    )
                  }
                }
              }
            }
          }
        }
      }
    `}
    render={data => {
      return (
        <Wrapper lightBlue>
          <Container>
            <Column>
              <SEOTitle white>Recent Blog Posts</SEOTitle>
              <BlogLayout>
                <Grid>
                  {data.allWpPost.nodes.map(node => {
                    const WpImage = getImage(
                      node.featuredImage.node.localFile.childImageSharp
                    )
                    const url = `blog/${node.slug}`

                    return (
                      <Link to={url} style={{ textDecoration: "none" }}>
                        <BlogWrapper>
                          <Column margin="0px 0 10px 0">
                            <GatsbyImage
                              image={WpImage}
                              alt="CheshTech William Cheshier"
                              placeholder="blurred"
                            />
                            <Text
                              fontweight="bold"
                              white
                              width="90%"
                              textAlign="center"
                            >
                              {node.title}
                            </Text>
                            <Text white small>
                              Date: {node.date}
                            </Text>
                            <Text white small width="90%">
                              {parse(node.excerpt)}
                            </Text>
                          </Column>
                        </BlogWrapper>
                      </Link>
                    )
                  })}
                </Grid>
              </BlogLayout>
              <Link to="/blog">
                <Button
                  background={Colors.darkBlue}
                  hover={Colors.black}
                  margin="30px 0 0 0"
                >
                  More Posts
                </Button>
              </Link>
            </Column>
          </Container>
        </Wrapper>
      )
    }}
  />
)
