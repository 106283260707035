import { graphql, useStaticQuery } from "gatsby"

export const useMarketingExamples = () => {
  const work = useStaticQuery(graphql`
    query {
      llt: file(relativePath: { eq: "clientwork/lltlogo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      zippota: file(relativePath: { eq: "clientwork/fulllogozippota.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
      nfd: file(relativePath: { eq: "clientwork/nfdlogo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED, formats: [AUTO, WEBP])
        }
      }
    }
  `)

  const marketingImages = [
    {
      img: work.llt.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Living Loving Team",
      type: "llt",
    },
    {
      img: work.zippota.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: Zippota",
      type: "zippota",
    },
    {
      img: work.nfd.childImageSharp.gatsbyImageData,
      alt: "CheshTech Work: NightFoxDude",
      type: "nfd",
    },
  ]
  return marketingImages
}
