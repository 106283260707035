import React, { useState, Fragment } from "react"
import { Subtitle, Text, Column, Button } from "../theme/index"
import { Colors } from "../theme/styles"
import { Modal } from "./modal"
import { StaticImage } from "gatsby-plugin-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { useMarketingExamples } from "../constants"

export const MarketingWorkExamples = () => {
  const [show, setShow] = useState(false)
  const [type, setType] = useState("")
  const marketingImages = useMarketingExamples()

  const toggleModal = key => {
    setShow(!show)
    if (key.type === "llt") {
      setType("llt")
    } else if (key.type === "zippota") {
      setType("zippota")
    } else {
      setType("nfd")
    }
  }
  return (
    <Fragment>
      {marketingImages.map((key, index) => {
        const image = getImage(key.img)
        return (
          <Column>
            <GatsbyImage
              image={image}
              alt={key.alt}
              placeholder="blurred"
              key={index}
            />
            <Button
              background={Colors.darkBlue}
              hover={Colors.black}
              margin="10px 0 0 0"
              onClick={() => toggleModal(key)}
            >
              Learn More
            </Button>
          </Column>
        )
      })}
      {type === "llt" && (
        <Modal show={show} toggleModal={toggleModal}>
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/clientwork/llt.jpeg"
              alt="CheshTech Real Estate Websites"
              placeholder="blurred"
              //   layout="fixed"
              width={500}
              height={250}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle textAlign="center" small>
              Living Loving Team: Real Estate Team
            </Subtitle>
            <Subtitle textAlign="center" small>
              Monthly Sales Funnel/Marketing Consulting
            </Subtitle>
            <Text>
              <strong>Project:</strong> Monthly marketing consulting and task
              delegation. CheshTech LLC serves as a marketing consultant and
              functional director for the Living Loving Team assigning their
              marketing team tasks, generating content ideas, and strategizing
              their overall real estate marketing approach.
            </Text>
          </Column>
        </Modal>
      )}
      {type === "nfd" && (
        <Modal show={show} toggleModal={toggleModal}>
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/clientwork/nfdlogo.png"
              alt="CheshTech Twitch Marketing"
              placeholder="blurred"
              //   layout="fixed"
              width={200}
              height={200}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle textAlign="center" small>
              NightFoxDude: Twitch Streamer and Internet Personality
            </Subtitle>
            <Subtitle small>
              Monthly Social Media Management & Consulting
            </Subtitle>
            <Text>
              <strong>Project:</strong> NightFoxDude is a Twitch Streamer and
              Internet Personality and we inherited his profile essentially from
              scratch. We helped rebrand his character image, create valuable
              content across Instagram, YouTube, Twitch, and Facebook. Our work
              helped bring him to Twitch Affliate status where our client is
              currently working his way towards a Twitch Partner to bring in
              even more revenue.
            </Text>
          </Column>
        </Modal>
      )}
      {type === "zippota" && (
        <Modal show={show} toggleModal={toggleModal}>
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/clientwork/zippotalogo.png"
              alt="CheshTech E-Commerce Websites for Entrepreneurs"
              placeholder="blurred"
              //   layout="fixed"
              width={250}
              height={150}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle textAlign="center" small>
              Zippota: Latina Owned LatinX Online Fashion Store
            </Subtitle>
            <Subtitle small>
              Monthly Social Media Management & Consulting
            </Subtitle>
            <Text>
              <strong>Project:</strong> Starting from scratch, we took the
              Zippota Online Store and rebranded the company by touching up
              their logo, spicing up their colors, niching down to the LatinX
              community and creating content templates for use across social
              media including Facebook, Instagram, and Pinterest. A fresh
              project in its early stages, we've seen great results thus far and
              look forward to continue to work with Zippota.
            </Text>
          </Column>
        </Modal>
      )}
    </Fragment>
  )
}
