import React from "react"
import styled from "styled-components"
import { Column, Button, SEOTitle, Text } from "../theme"
import { Colors, Responsive } from "../theme/styles"
import {
  FaApple,
  FaSpotify,
  FaYoutube,
  FaStar,
  FaInstagram,
} from "react-icons/fa"

const Background = styled.div`
  background: ${Colors.darkBlue};
  width: 100%;
  padding: 50px;
`

const Grid = styled.div`
  ${Responsive.sm`
display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  width: 100%;
  grid-gap: 15px;
  margin-top: 25px;
`}
  ${Responsive.lg`
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
`}
`

const data = [
  {
    icon: <FaApple color={Colors.white} size={60} />,
    text: "Apple Podcasts",
    btnText: "Listen Now",
    link: "https://podcasts.apple.com/us/podcast/talkin-solutions/id1577694043",
  },
  {
    icon: <FaSpotify color={Colors.white} size={60} />,
    text: "Spotify",
    btnText: "Listen Now",
    link: "https://open.spotify.com/show/5LHkUxhqPHi9PjRoZsTZT7",
  },
  {
    icon: <FaYoutube color={Colors.white} size={60} />,
    text: "YouTube",
    btnText: "Watch Now",
    link: "https://www.youtube.com/channel/UCU_oYrM04cCtND7piqHVtMw/featured?sub_confirmation=1",
  },
  {
    icon: <FaInstagram color={Colors.white} size={60} />,
    text: "Follow on IG",
    btnText: "Follow Now",
    link: "https://instagram.com/talkinsolutionspodcast",
  },
  {
    icon: <FaStar color={Colors.white} size={60} />,
    text: "5 Star Rating",
    btnText: "See Reviews",
    link: "https://podcasts.apple.com/us/podcast/talkin-solutions/id1577694043",
  },
]

export const SpanBar = () => {
  return (
    <Background>
      <Column>
        <SEOTitle white>How to Listen</SEOTitle>
        <Grid>
          {data.map((key, index) => {
            return (
              <Column key={index}>
                <Column>{key.icon}</Column>
                <Text white>{key.text}</Text>
                <a href={key.link} target="__blank">
                  <Button margin="10px 0 0 0" background={Colors.lightBlue}>
                    {key.btnText}
                  </Button>
                </a>
              </Column>
            )
          })}
        </Grid>
      </Column>
    </Background>
  )
}
