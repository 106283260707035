import React, { useState, Fragment } from "react"
import styled from "styled-components"
import { Colors, Responsive } from "../theme/styles"

const Wrapper = styled.span`
  position: relative;
`

const Trigger = styled.span`
  display: flex;
`

const Bubble = styled.div`
  width: 200px;
  height: auto;
  background-color: ${Colors.darkBlue};
  border-radius: 2px;
  position: absolute;
  z-index: 10;
  top: 100%;
  left: 50%;
  padding-top: 9px;
  transform: translateX(-50%);
  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-bottom: 9px solid red;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
`

const Message = styled.div`
  border-radius: 3px;
  color: white;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  width: 100%;
  padding: 4px 10px;
  align-items: center;
  justify-content: center;
  text-align: center;
`

const Menu = styled.div`
  ${Responsive.sm`
width: 175px;
  height: auto;
  border-radius: 8px;
  box-shadow: 0 8px 24px 0 rgba(21, 67, 183, 0.08);
  border: solid 1px #e9ebec;
  background-color: #ffffff;
  position: absolute;
  z-index: 10;
  top: 100%;
  right: -55%;
  // padding-top: 8px;
  // margin-top: 8px;
`}
  ${Responsive.md`
  width: 250px;
  right: -60%;
`}
`

export const Tooltip = ({ message, type, links, children }) => {
  const [show, setShow] = useState(false)
  return (
    <Wrapper onMouseLeave={() => setShow(false)}>
      {show && type !== "menu" && (
        <Fragment>
          <Bubble>
            <Message>{message}</Message>
          </Bubble>
        </Fragment>
      )}
      {show && type === "menu" && (
        <Fragment>
          <Menu>{links}</Menu>
        </Fragment>
      )}
      {type !== "menu" && (
        <Trigger onClick={() => setShow(!show)}>{children}</Trigger>
      )}
      {type === "menu" && (
        <Trigger onClick={() => setShow(!show)}>{children}</Trigger>
      )}
    </Wrapper>
  )
}
