import React, { Fragment } from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {
  SEOTitle,
  Subtitle,
  Container,
  Text,
  Column,
  Wrapper,
  Button,
} from "../theme/index"
import { Colors, Responsive } from "../theme/styles"
import {
  FaLaptopCode,
  FaHandshake,
  FaFileSignature,
  FaEdit,
  FaDraftingCompass,
  FaRocket,
  FaFile,
  FaBullseye,
  FaFunnelDollar,
} from "react-icons/fa"
import { GiCruiser } from "react-icons/gi"

const Grid = styled.div`
  ${Responsive.sm`
display: grid;
grid-template-columns: repeat(auto-fit, 300px);
gap: 45px;
width: 100%;
margin-top: 30px;
justify-content: center;
`}
  ${Responsive.lg`
display: grid;
grid-template-columns: repeat(auto-fit, 325px);
gap: 45px;
width: 100%;
margin-top: 30px;
justify-content: center;
`}
`

const homeData = [
  {
    title: "Introductory Meeting",
    text: `We'll talk about your problem and the solutions you are looking for in a 30 minute consultation to discover the best solution for you.`,
    icon: <FaHandshake color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Proposal and Estimate",
    text: `Based off our conversation, we'll align your goals and needs and create a custom proposal and estimate for your specific project.`,
    icon: <FaFileSignature color={Colors.darkBlue} size={60} />,
  },
  {
    title: "We Deliver Your Results",
    text: `You'll sit back and relax as we do our thing keeping you updated throughout the process and then deliver the final product to you so you can start earning more revenue!`,
    icon: <FaFunnelDollar color={Colors.darkBlue} size={60} />,
  },
]

const podcastData = [
  {
    title: "Fill out contact form",
    text: `Fill out the contact form with your information and podcast host and CheshTech CEO Will Cheshier will get back to you ASAP with options for an intro call.`,
    icon: <FaHandshake color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Introduction Call",
    text: `We'll have a casual intro call so I can learn more about you and your impact driven company and how the podcast works.`,
    icon: <FaFileSignature color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Let the Fun Begin!",
    text: `After the intro call, we'll set a date and record the podcast! We'll feature you all week on our podcast social media account to highlight + promote your company.`,
    icon: <FaFunnelDollar color={Colors.darkBlue} size={60} />,
  },
]

const websiteData = [
  {
    title: "Introductory Meeting",
    text: `We'll talk about your problem and the solutions you are looking for in a FREE consultation to see if we are a match to do business together.`,
    icon: <FaHandshake color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Initial Proposal",
    text: `Based off our conversation, we'll align your goals and needs and create a custom proposal and estimate for your specific project.`,
    icon: <FaFileSignature color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Goal Setting",
    text: `Upon agreement, we'll meet to discuss your goals for your business and your website to make sure we build a website that aligns with your company's goals.`,
    icon: <FaBullseye color={Colors.darkBlue} size={60} />,
  },
  {
    title: "UX Design",
    text: `We'll start the design process based on the goals for your website. Our design will be conversion centric and we'll provide you an opportunity to review our designs before moving forward with development.`,
    icon: <FaDraftingCompass color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Website Revisions",
    text: `Once the initial designs are done, we'll give you an opportunity to review the designs and make any edits that you desire. Our goal is to provide you a website design and user interface that you are proud of.`,
    icon: <FaEdit color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Development Begins",
    text: `After you give us the go ahead on the designs, the fun begins! We'll start the development process to build you a high performing, fully optimized, mobile friendly, and with SEO implementations so your website becomes the 24/7 sales tool it's meant to be.`,
    icon: <FaLaptopCode color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Website Launch Strategy",
    text: `During the development process we'll connect with you and your marketing team to make sure we have full communication on the best strategies to officially launch your website.`,
    icon: <FaFile color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Final Launch",
    text: `When we complete the development process, you'll have another opportunity to submit any revisions you'd like for the website. Then we'll launch the website for your audience to see and watch as your goals begin to come to fruition!`,
    icon: <FaRocket color={Colors.darkBlue} size={60} />,
  },
]

const marketingData = [
  {
    title: "Introductory Meeting",
    text: `We'll talk about your problem and the solutions you are looking for in a FREE consultation to see if we are a match to do business together.`,
    icon: <FaHandshake color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Initial Proposal and Estimate",
    text: `Based off our conversation, we'll align your goals and needs and create a custom proposal and estimate for your specific project.`,
    icon: <FaFileSignature color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Goal Setting",
    text: `Upon agreement on a proposal, we'll sit down and set both short and long term goals that are challenging, but attainable for your business.`,
    icon: <FaBullseye color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Marketing Strategy Document",
    text: `We'll get to work and deliver an in-depth and complete Marketing Strategy Document outlining your goals, our marketing strategy, competitor analysis, and how we expect to meet your goals and grow your business.`,
    icon: <FaDraftingCompass color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Content Creation and Social Media Branding",
    text: `Establishing your branding and social media presence is an important step to the long term success of your marketing plan. During this time we'll use your branding, or re-brand your content with attractive, dynamic, and conversion centric social media templates.`,
    icon: <FaEdit color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Content + Inbound Marketing",
    text: `We want leads to come to you. In this stage, we'll start creating content such as blogs, vlogs, email newsletters, and more so your target market knows you're the experts in your industry and the best company to do business with. `,
    icon: <FaLaptopCode color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Cruise on AutoPilot",
    text: `At this stage, we'll have done all the upfront work with you and you can sit back and relax as we do the work for you as you watch new leads come in, and watch your revenue grow.`,
    icon: <GiCruiser color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Weekly Meetings",
    text: `We'll dedicate a weekly meeting at a time slot that works for you to discuss our progress and ways we can continue to innovate going forward.`,
    icon: <FaRocket color={Colors.darkBlue} size={60} />,
  },
]

const brandingData = [
  {
    title: "Introductory Meeting",
    text: `We'll discuss what issues you're having with your branding and content creation strategies. Then we'll get down into the details of your business goals and how your branding is meant to help grow your business.`,
    icon: <FaHandshake color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Initial Proposal and Estimate",
    text: `Based off our conversation, we'll align your goals and needs and create a custom proposal and estimate for your specific project.`,
    icon: <FaFileSignature color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Branding Meeting",
    text: `Upon agreement on a proposal, we'll get the ball rolling by really diving into how we feel is best to present your brand to your target audience. We'll go through colors, aesthetics, logo design ideas, slogans, and more before jumping in 100% to ensure we create a brand you and your customers love.`,
    icon: <FaBullseye color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Moodboard Creation + Branding Guidelines",
    text: `Now the real fun begins! After our branding meeting, the CheshTech team will begin our branding process by creating our initial moodboard and branding guidelines. The moodboard will contain your logo, social media designs, marketing material, and more. Upon completion, we'll send the first draft over to you to get your feedback.`,
    icon: <FaDraftingCompass color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Revisions",
    text: `Now is the opportunity to make sure we have the exact branding that you envision for your business, We'll send over the moodboard and give you 3 revisions to make sure we have the proper branding for your business that will attract the most customers to your brand.`,
    icon: <FaEdit color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Final Draft and Delivery",
    text: `At this stage, we'll complete the final draft of the moodboard and finish the branding material for you to keep! This includes logos, branding guideline document, social media templates, and marketing material! With the fresh re-branding, your business will be ready to put your branding to good use to generate more revenue for your business.`,
    icon: <FaLaptopCode color={Colors.darkBlue} size={60} />,
  },
]

const technologyData = [
  {
    title: "Schedule a Consultation",
    text: `Schedule a consultation and let us know what you're goals are and what you want out of the meeting. We'll make sure to prep and be ready to engage in a productive conversation about the best steps forward for you and your goals.`,
    icon: <FaHandshake color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Introductory Meeting",
    text: `We'll meet and go over what your goals are for your product and development processes. At the end of the meeting, our experts will provide you with meaningful feedback and best recommendations moving forward.`,
    icon: <FaFileSignature color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Crafting Your Estimate and Proposal",
    text: `When agreed upon, we'll get started working on building a solution that is best suited for you and your product goals. Our proposal and estimate will be crafted with care with your best interests in mind.`,
    icon: <FaBullseye color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Proposal Review",
    text: `Upon completion of the proposal, we'll go over the proposal with you to ensure we've met all of your needs and goals within the proposal. You'll have an opportunity to properly review and get back to us at a time that works best for you.`,
    icon: <FaDraftingCompass color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Getting started",
    text: `Our next steps after the approval of the proposal and signing of the contract will be to dive even further into your product and start the process of developing an in-depth strategy for your product. We'll meet again to ensure we're on the same page to begin the development or sourcing of your project.`,
    icon: <FaEdit color={Colors.darkBlue} size={60} />,
  },
  {
    title: "Development Process",
    text: `Now is the fun part! We'll start the design, development, and system building process for your product. Of course, we'll keep you updated on a regular basis as our team of experts get started on creating a product that will drive growth to your business and provide your user with an optimized product. `,
    icon: <FaLaptopCode color={Colors.darkBlue} size={60} />,
  },
]

export const Process = ({ type }) => {
  return (
    <Fragment>
      {type === "home" && (
        <Wrapper lightBlue>
          <Container>
            <SEOTitle white textAlign="center">
              A Stress Free Process
            </SEOTitle>
            <Grid>
              {homeData.map((key, index) => {
                return (
                  <Column key={index}>
                    {key.icon}
                    <Subtitle white textAlign="center">
                      {key.title}
                    </Subtitle>
                    <Text white textAlign="center">
                      {key.text}
                    </Text>
                  </Column>
                )
              })}
            </Grid>
            <Column>
              <Link to="/schedule-consultation">
                <Button
                  background={Colors.darkBlue}
                  hover={Colors.black}
                  margin="30px 0 0 0"
                >
                  Book Consultation
                </Button>
              </Link>
            </Column>
          </Container>
        </Wrapper>
      )}
      {type === "podcast" && (
        <Wrapper lightBlue>
          <Container>
            <SEOTitle white textAlign="center">
              Interested in highlighting your solution?
            </SEOTitle>
            <Grid>
              {podcastData.map((key, index) => {
                return (
                  <Column key={index}>
                    {key.icon}
                    <Subtitle white textAlign="center">
                      {key.title}
                    </Subtitle>
                    <Text white textAlign="center">
                      {key.text}
                    </Text>
                  </Column>
                )
              })}
            </Grid>
            <Column>
              <Link to="/contact">
                <Button
                  background={Colors.darkBlue}
                  hover={Colors.black}
                  margin="30px 0 0 0"
                >
                  Fill Out Form
                </Button>
              </Link>
            </Column>
          </Container>
        </Wrapper>
      )}
      {type !== "home" && type !== "podcast" && (
        <Wrapper>
          <Container>
            <SEOTitle textAlign="center">Our Process</SEOTitle>
            <Grid>
              {type === "webdev" &&
                websiteData.map((key, index) => {
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Subtitle textAlign="center">{key.title}</Subtitle>
                      <Text textAlign="center">{key.text}</Text>
                    </Column>
                  )
                })}
              {type === "marketing" &&
                marketingData.map((key, index) => {
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Subtitle textAlign="center">{key.title}</Subtitle>
                      <Text>{key.text}</Text>
                    </Column>
                  )
                })}
              {type === "branding" &&
                brandingData.map((key, index) => {
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Subtitle textAlign="center">{key.title}</Subtitle>
                      <Text>{key.text}</Text>
                    </Column>
                  )
                })}
              {type === "technology" &&
                technologyData.map((key, index) => {
                  return (
                    <Column key={index}>
                      {key.icon}
                      <Subtitle textAlign="center">{key.title}</Subtitle>
                      <Text>{key.text}</Text>
                    </Column>
                  )
                })}
            </Grid>
            <Column>
              <Link to="/schedule-consultation">
                <Button
                  background={Colors.darkBlue}
                  hover={Colors.black}
                  margin="30px 0 0 0"
                >
                  Book Consultation
                </Button>
              </Link>
            </Column>
          </Container>
        </Wrapper>
      )}
    </Fragment>
  )
}
