import React, { useState } from "react"
import styled from "styled-components"
import { graphql, useStaticQuery } from "gatsby"
import { Modal } from "./modal"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { StaticImage } from "gatsby-plugin-image"
import {
  Container,
  Wrapper,
  Column,
  Button,
  SEOTitle,
  Subtitle,
  Text,
} from "../theme"
import { Colors } from "../theme/styles"

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, 325px);
  gap: 45px;
  width: 100%;
  margin-top: 30px;
  justify-content: center;
  @media (max-width: 450px) {
    grid-template-columns: repeat(auto-fit, 300px);
  }
`

const DynamicWrapper = styled(Wrapper)`
  background: ${props => props.background};
`

const StyledTitle = styled(SEOTitle)`
  color: ${props => props.color || `${Colors.black}`};
  font-size: ${props => props.fontSize};
  text-align: center;
`

const StyledText = styled(Text)`
  color: ${props => props.color || `${Colors.black}`};
  font-size: 18px;
  font-weight: bold;
  margin: ${props => props.margin};
`

export const LeadershipTeam = ({ background, color, tipo }) => {
  const [show, setShow] = useState(false)
  const [type, setType] = useState("")

  const team = useStaticQuery(graphql`
    query {
      whatley: file(relativePath: { eq: "team/whatley.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            height: 243
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      will: file(relativePath: { eq: "ctawill.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 400
            height: 300
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
      raquel: file(relativePath: { eq: "team/raquel.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 300
            height: 243
            placeholder: BLURRED
            formats: [AUTO, WEBP]
          )
        }
      }
    }
  `)

  const data = [
    {
      img: team.raquel.childImageSharp.gatsbyImageData,
      name: "Raquel Cespedes Diaz",
      title: "CMO (Chief Marketing Officer) and Owner of Digital Raquel",
    },
    {
      img: team.will.childImageSharp.gatsbyImageData,
      name: "William Sucre Cheshier",
      title: "Owner of CheshTech & Host of Talkin' Solutions Podcast",
    },
    {
      img: team.whatley.childImageSharp.gatsbyImageData,
      name: "Will Whatley",
      title: "CTO (Chief Technical Officer) of CheshTech",
    },
  ]

  const toggleModal = key => {
    setShow(!show)
    if (key.name === "Raquel Cespedes Diaz") {
      setType("Raquel")
    } else if (key.name === "Will Whatley") {
      setType("Whatley")
    } else {
      setType("Will")
    }
  }

  return (
    <DynamicWrapper background={background}>
      <Container>
        <Column>
          {tipo === "cto" && (
            <StyledTitle color={color}>
              Working Directly With Our CTO
            </StyledTitle>
          )}
          {tipo !== "cto" && (
            <StyledTitle color={color}>Leadership Team</StyledTitle>
          )}
          {tipo === "cto" && (
            <Column>
              <StaticImage
                src="../images/team/whatley.jpg"
                alt="CheshTech William Whatley"
                placeholder="blurred"
                //   layout="fixed"
                width={200}
                height={200}
                style={{ marginBottom: "10px", borderRadius: "10px" }}
              />
              <StyledTitle color={color}>
                Meet Your Fractional CTO: William Whatley
              </StyledTitle>
              <Button
                margin="10px 0 0 0"
                background={Colors.darkBlue}
                hover={Colors.black}
                onClick={() => setShow(!show)}
              >
                Learn More
              </Button>
            </Column>
          )}
          <Grid>
            {tipo !== "cto" &&
              data.map((key, index) => {
                const image = getImage(key.img)
                return (
                  <Column key={index}>
                    <GatsbyImage
                      image={image}
                      alt="CheshTech William Cheshier"
                      placeholder="blurred"
                      style={{ marginBottom: "10px", borderRadius: "5px" }}
                    />
                    <Column margin="15px 0 0 0">
                      <StyledTitle fontSize="22px" color={color}>
                        {key.name}
                      </StyledTitle>
                      <StyledText
                        margin="10px 0"
                        textAlign="center"
                        color={color}
                      >
                        {key.title}
                      </StyledText>
                      <Button
                        margin="10px 0 0 0"
                        background={Colors.darkBlue}
                        hover={Colors.black}
                        onClick={() => toggleModal(key)}
                      >
                        Learn More
                      </Button>
                    </Column>
                  </Column>
                )
              })}
          </Grid>
        </Column>
      </Container>
      <Modal show={show} toggleModal={toggleModal}>
        {type === "Raquel" && (
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/team/raquel.jpg"
              alt="CheshTech Raquel Cespedes Diaz"
              placeholder="blurred"
              //   layout="fixed"
              width={200}
              height={200}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle>Meet Raquel Cespedes Diaz</Subtitle>
            <Text>
              Raquel has been with CheshTech since January of 2021! She began as
              the social media marketing manager and is now in charge of
              marketing for our client projects. She's Latina and proud having
              been born in Peru before moving to France while she was young.
              Raquel speaks an impressive three languages fluently (Spanish,
              French, and English). She specializes in social media marketing,
              branding, and content creation. Her emphasis is on engagement and
              organic growth to build, engage, and keep a consistent and loyal
              brand of followers to your brand.
            </Text>
          </Column>
        )}
        {type === "Will" && (
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/team/chesh.jpg"
              alt="CheshTech William Cheshier"
              placeholder="blurred"
              //   layout="fixed"
              width={200}
              height={200}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle>Meet William Sucre Cheshier</Subtitle>
            <Text>
              Will is the founder and owner of CheshTech and is a technology and
              marketing professional. He specializes on building websites that
              help you capture more leads and generate more business. He does
              this by focusing on CRO (Coversion Rate Optimization) and
              developing websites using the latest technology to ensure
              performance, security, and accessibility are optimized for
              conversions. He is a native of Bothell, WA in the U.S. and is
              passionate about health and fitness and loves to travel.
            </Text>
          </Column>
        )}
        {type === "Whatley" && (
          <Column width="90%" margin="0 auto 15px auto">
            <StaticImage
              src="../images/team/whatley.jpg"
              alt="CheshTech William Whatley"
              placeholder="blurred"
              //   layout="fixed"
              width={200}
              height={200}
              style={{ marginBottom: "10px", borderRadius: "10px" }}
            />
            <Subtitle>Meet William Whatley</Subtitle>
            <Text>
              Will has 6 years of experience working as a software engineer and
              as a Product Manager. He's been the CTO of CheshTech since it's
              inception back in April of 2020. He has immense knowledge and has
              built MVP's, product features and iterations, and has his own
              startups. He has experience with big data, machine learning,
              native and web apps. Combined, he has designed, developed,
              deployed, and managed applications with over 90,000 users and $3M
              in ARR. Whatley also speaks Spanish as a second language.
            </Text>
          </Column>
        )}
      </Modal>
    </DynamicWrapper>
  )
}
